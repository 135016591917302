import './googlesignup.scss'
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import * as amplitude from '@amplitude/analytics-browser';

const GoogleSignUp = () => {

    async function handleGoogleSignUp() {
        amplitude.track('Google Sign Up Btn Clicked')
        await Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google, customState: "GoogleSignin"})
    }

    return ( 
        <div className="googlesignup" onClick={()=> handleGoogleSignUp()}>
                <img className="navicon" src="https://d23k6qsajefhq1.cloudfront.net/platform-media/googlelogoicon.png"/>Sign Up with Google
        </div>
     );
}
 
export default GoogleSignUp;