import './verifybtn.scss'

const VerifyBtn = (props) => {
    return ( 
        <div className="signinbtn" onClick={(e) => props.HandleVerification(e)}>
            Verify Your Account
        </div>
     );
}
 
export default VerifyBtn;