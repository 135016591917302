import './deletevidpopup.scss'
import { useGlobalState } from '../../../../../Hooks/useGlobalState';
import { useCallback } from 'react';
import { deleteStoryPromise, getRootDataPromise } from '../../../../../API/promiseAPI'
import set_state_data from '../../../../../Reducers/Data/setData'



const DeleteVideoPopup = (props) => {
    // set editpopupclose..
    // set setdeletepopupclose..


    const setIsLoading = useCallback(isLoading => dispatch(
        {
            ...state,
            status: {
                ...state.status,
                isLoading : isLoading,
            }
        }
    ), [])

    const [state, dispatch] = useGlobalState();


    async function HandleVideoDelete(userId, storyId, holoId){
        setIsLoading(true);
        await deleteStoryPromise(userId, holoId, storyId, state.session)
        .then(() => {
            return getRootDataPromise(userId, state.session);
        })
        .then((data) => {
            set_state_data(state, dispatch, data);
        })
        .then(() => {
            props.setTrigger(false, ()=> props.setDeletePopupState(false, () =>  setIsLoading(false)));
        })
    }
    
    return (props.deletepopupstate) ? (
        <div className="delete-vid-popup">
            <div className="delete-vid-popup-inner">
                <h3>Are you sure?</h3>
                <text id='are-you-sure-text'>Deleting this video will erase all its contents</text>
                <div className='delete-btn-container'>
                <button onClick={() => props.setTrigger(false)} id='cancel-btn'>Cancel</button>
                <button id='confirm-del-vid-btn' onClick={async () => { await HandleVideoDelete(state.data.userData.userId, props.data.storyId, state.currentHolo)}}>Delete</button>
                </div>
                {props.children}
            </div>
        </div>
     ): "";
}



const DeleteVideoPopupContainer = (props) => {

    
    return (
        <DeleteVideoPopup {...props}> </DeleteVideoPopup>
    )
    
}
 
export default DeleteVideoPopupContainer;