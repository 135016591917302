import './signupbtn.scss'
import {Link} from 'react-router-dom';

const SignupBtn = (props) => {
    return ( 
        <div className="signupbtn" onClick={(e) => props.handleSignUp(e)}>
            Sign Up
        </div>
     );
}
 
export default SignupBtn;