import './videotext.scss'
import * as amplitude from '@amplitude/analytics-browser';

const VideoText = (props) => {

    function storyTypeDropdown (e) {
        props.setStoryType(e.target.value)
        amplitude.track('Story Type Dropdown Clicked');
    }

    return (  
        <div>
            <div className="video-header">
                <div>Add Story</div>
                <select onChange={(e) =>storyTypeDropdown(e)}  id='select-story-dropdown' value={props.storyType}>
                    <option value="Basic">Basic</option>
                    <option value="Shoppable">Shoppable</option>
                </select>
            </div>
        </div>
    );
}
 
export default VideoText;