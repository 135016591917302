import './navbar.scss'
import {Link, useResolvedPath} from 'react-router-dom';
import { useEffect, useState } from 'react';
import {ReactComponent as Addition} from '../../Assets/Icons/addition.svg';
import CreateProjectPopup from './CreateProject/CreateProjectPopup';
import { useGlobalState } from '../../Hooks/useGlobalState';
import {ReactComponent as Folder} from '../../Assets/Icons/folder.svg';
import {ReactComponent as Apps} from '../../Assets/Icons/apps.svg';
import {ReactComponent as Graph} from '../../Assets/Icons/graph.svg';
import {ReactComponent as Integration} from '../../Assets/Icons/link.svg';
import * as amplitude from '@amplitude/analytics-browser';


const NavBar = (props) => {
    const [state, dispatch] = useGlobalState();

    const [activetab, setactivetab] = useState(props.navstate);
    const [createProjectPopup, setCreateProjectPopup] = useState(false);

    const toggleTab = (navIndex, currentProject) => {
        setactivetab(navIndex);
        dispatch({...state, currentProject: currentProject});
    }

    useEffect(() => {
        if(state.currentProject == null && activetab == 1){
            setCreateProjectPopup(true);
        }
    }, [state.currentProject])

    function HandleProjectsClicks(){
        var currentProject = props.currentProject;
        if(currentProject != null){
            toggleTab(1, currentProject);
            return;
        }
        
        if(state.data.userData.projectIds.length > 0){
            currentProject = state.data.userData.projectIds[0];
            toggleTab(1, currentProject);
            return;
        }
        toggleTab(1, props.currentProject);
    }

    function analyticsClicked () {
        amplitude.track('Analytics Tab Clicked');
        toggleTab(2,props.currentProject )
    }

    function integrationsClicked () {
        amplitude.track('Integrations Tab Clicked');
        toggleTab(3,props.currentProject);
    }

    return (  
        <div className="nav-bar">
            <div className="nav-tab-container">
                <div className="create-project-btn" onClick={()=>setCreateProjectPopup(true)}><Addition/></div>
                <Link to="/stores"> <div className="project-tab-header" onClick={()=> HandleProjectsClicks()}><div style={activetab==1 ? {backgroundColor:"#0094FF"} : {backgroundColor:"#fff"}} className='nav-tab-selected'/><Apps/>&nbsp;&nbsp;STORES</div></Link><br></br>
                <div id="project-tab-container">
                {
                    state.data.userData.projectIds && state.data.userData.projectIds.map((projectId) => {
                        return (
                            <><Link to="/stores"> <div className={props.currentProject === projectId ? "project-tab-selected" : "project-tab"} onClick={() => toggleTab(1, projectId)}> {state.data.projectMap[projectId].projectName}</div></Link><br></br></>
                        )
                    })
                }
                </div>
                <br></br><Link to="/assets"><div className="nav-tab" onClick={()=> integrationsClicked()}><div style={activetab==4 ? {backgroundColor:"#0094FF"} : {backgroundColor:"#fff"}} className='nav-tab-selected'/><Folder/>&nbsp;&nbsp;ASSETS</div></Link>
                <br></br><Link to="/analytics"><div className="nav-tab" onClick={()=> analyticsClicked()}><div style={activetab==2 ? {backgroundColor:"#0094FF"} : {backgroundColor:"#fff"}} className='nav-tab-selected'/><Graph/>&nbsp;&nbsp;ANALYTICS</div></Link><br></br>
                <Link to="/integrations"><div className="nav-tab" onClick={()=> integrationsClicked()}><div style={activetab==3 ? {backgroundColor:"#0094FF"} : {backgroundColor:"#fff"}} className='nav-tab-selected'/><Integration style={{width:"20px"}}/>&nbsp;&nbsp;INTEGRATIONS</div></Link><br></br>
            </div>
            <div id='support-container'>
                <span className="project-tab-header" >Support</span>
                <a href='https://holocommerce.gitbook.io/get-started/get-started/the-basics' target="_blank" rel="noopener noreferrer"><button id="support-btn" onClick={() => amplitude.track('Read Onboarding Docs Btn Clicked')}>Read Onboarding Docs</button></a>
                <a href='https://calendly.com/holo-commerce/30min' target="_blank" rel="noopener noreferrer"><button id="support-btn">Schedule Support Call</button></a>
            </div>
            <CreateProjectPopup setCreateProjectPopup={setCreateProjectPopup} createProjectPopup={createProjectPopup}></CreateProjectPopup>
        </div>
    );
}
 
export default NavBar;
