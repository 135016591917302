
import './VerificationContainer.scss'
import VerifyBtn from '../../Components/VerificationElements/VerifyBtn/verifybtn';
import VerifyHeader from '../../Components/VerificationElements/VerifyHeader/verifyheader';
import VerifySignupLink from '../../Components/VerificationElements/VerifySignupLink/verifysignuplink';
import VerifyInfo from '../../Components/VerificationElements/VerifyInfo/verifyinfo';
import {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import React from "react";
import { Auth } from "aws-amplify";
import * as amplitude from '@amplitude/analytics-browser';

function VerificationContainer() {
    const [loading, setLoading] = React.useState(false);
    const [emailField, setEmailField] = useState("");
    const [codeField, setCodeField] = useState("");
    const navigate = useNavigate();
    
    const fields = {
        setCodeField: setCodeField,
        setEmailField, setEmailField
    }

    const HandleVerification = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
          await Auth.confirmSignUp(emailField, codeField);
          navigate("/signin");
          amplitude.setUserId(emailField);
          amplitude.track('User Signed Up');
        } catch (error) {
          console.error(error)
        }
        setLoading(false);
      };

    return (  
        <div className="verificationContainer">
            <VerifyInfo fields={fields}></VerifyInfo>
            <VerifyBtn HandleVerification={HandleVerification}></VerifyBtn>
            <VerifyHeader></VerifyHeader>
            <VerifySignupLink></VerifySignupLink>
        </div>
    );
}

export default VerificationContainer;