import SigninContainer from "../Containers/SigninContainer/SigninContainer";
import HeaderBarSignIn from "../Components/SigninElements/HeaderBar/headerbar-signin";

const Signin = () => {
    return (
        <div>
            <HeaderBarSignIn></HeaderBarSignIn>
            <SigninContainer></SigninContainer>
        </div>
    );
}

export default Signin;
