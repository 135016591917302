import NavBar from "../Components/NavbarElement/navbar";
import HeaderBar from "../Components/NavbarElement/headerbar";
import AnalyticsText from "../Components/Analytics/AnalyticsText/analyticstext";
import AnalyticsGrid from "../Components/Analytics/AnalyticsGrid/analyticsgrid";
import AnalyticsTable from "../Components/Analytics/AnalyticsTable/analyticstable";
import { useGlobalState } from "../Hooks/useGlobalState";
import React, { useEffect, useState } from "react";
import {getBatchEventsPromise} from '../API/promiseAPI';
import * as amplitude from '@amplitude/analytics-browser';


function GetAnalyticsTableData(data, currentAnalyticsProject, currentAnalyticsHolo, analyticsData){
    var retData = []
    if (currentAnalyticsHolo==0) {
        for (var holoId of Object.keys(data.holoMap)) {
            var storyIds = data.holoMap[holoId].storyIds;
            for(var storyId of storyIds){
                const storyClone = structuredClone(data.storiesMap[storyId])
                storyClone['id'] = storyClone.storyId;
                storyClone['productTitle'] = JSON.parse(data.storiesMap[storyId].productData).title;
                storyClone['views'] = analyticsData[buildVideoViewEvent(holoId, storyId)];
                storyClone['addToCartClicks'] = analyticsData[buildAddToCartClicks(holoId, storyId)];
                storyClone['ctaClicks'] = analyticsData[buildVideoCtaEvent(holoId, storyId)];
                storyClone['holoName'] = data.holoMap[holoId].holoName;
                if(currentAnalyticsProject == 0 || storyClone.projectId == currentAnalyticsProject){
                    retData.push(storyClone);
                }
            }
        }
    }
    else {
        var storyIds = data.holoMap[currentAnalyticsHolo].storyIds;
        for(var storyId of storyIds){
            const storyClone = structuredClone(data.storiesMap[storyId])
            storyClone['id'] = storyClone.storyId;
            storyClone['views'] = analyticsData[buildVideoViewEvent(currentAnalyticsHolo, storyId)];
            storyClone['ctaClicks'] = analyticsData[buildVideoCtaEvent(currentAnalyticsHolo, storyId)];
            storyClone['addToCartClicks'] = analyticsData[buildAddToCartClicks(currentAnalyticsHolo, storyId)];
            storyClone['holoName'] = data.holoMap[currentAnalyticsHolo].holoName;
            storyClone['productTitle'] = JSON.parse(data.storiesMap[storyId].productData).title;
            retData.push(storyClone);
        }
    }
    return retData;
}

function GetAnalyticsGridData(data, currentAnalyticsProject, currentAnalyticsHolo, analyticsData){

    var tableData = GetAnalyticsTableData(data, currentAnalyticsProject, currentAnalyticsHolo, analyticsData);

    var retData = {
        ctaClicks: 0,
        videoViews: 0,
        projectViews: 0,
        projectSessions: 0,
        checkoutClicks: 0,
        addToCartClicks: 0,
        projectSessionDuration: 0
    };

    retData['videoViews'] = tableData.reduce((accum, item) => accum + item.views, 0);
    retData['ctaClicks'] = tableData.reduce((accum, item) => accum + item.ctaClicks, 0);
    retData['addToCartClicks'] = tableData.reduce((accum, item) => accum + item.addToCartClicks, 0);

    if (currentAnalyticsProject==0) {
        for (var projectId of Object.keys(data.projectMap)) {
          retData['projectViews'] += analyticsData[buildProjectViewEvent(projectId)];
          retData['projectSessionDuration'] += analyticsData[buildProjectSessionDurationEvent(projectId)];
          retData['projectSessions'] += analyticsData[buildProjectSessionEvent(projectId)];
          retData['checkoutClicks'] += analyticsData[buildCheckoutClicksEvent(projectId)];
        }
    }
    else {
        retData['projectViews'] = analyticsData[buildProjectViewEvent(currentAnalyticsProject)];
        retData['projectSessionDuration'] += analyticsData[buildProjectSessionDurationEvent(currentAnalyticsProject)];
        retData['projectSessions'] = analyticsData[buildProjectSessionEvent(currentAnalyticsProject)];
        retData['checkoutClicks'] += analyticsData[buildCheckoutClicksEvent(currentAnalyticsProject)];
    }

    return retData;
}


function AnalyticsPage() {
    const [state, dispatch] = useGlobalState();

    // sets to the current analytics state:
    const [currentAnalyticsHolo, setAnalyticsHolo] = useState(0);
    const [currentAnalyticsProject, setAnalyticsProject] = useState(0);

    const [loading, setLoading] = useState(true);

    useEffect(()=> {
        var totalEvents = getTotalEvents(state)
        var analyticsData = async () => {
            await getBatchEventsPromise(totalEvents, state.session).then((analyticsMap) => {
                analyticsMap = JSON.parse(analyticsMap);
                setAnalyticsData(analyticsMap);
            })
        }
        analyticsData();
    }, [])
    const [analyticsData, setAnalyticsData] = useState(null);


    const [holoDataDropdown, setHoloDataDropdown] = useState(state.data.holoMap);
    useEffect(() => {
        if (currentAnalyticsProject != 0) {
            var projectholo = {};
            {state.data.projectMap[currentAnalyticsProject].holoIds.map((id) => {
                projectholo[id] = state.data.holoMap[id];
            })};
            setHoloDataDropdown(projectholo);
        }
        else {
            setHoloDataDropdown(state.data.holoMap)
        }
    }, [currentAnalyticsProject])


    amplitude.track('Analytics Page Viewed')


    useEffect(() => {
        if(analyticsData != null){
            setLoading(false);
        }
    }, [analyticsData])



    return !loading && (
        <div>
        <NavBar navstate={2}/>
        <HeaderBar/>
        <AnalyticsText projectData={state.data.projectMap} holoMap={holoDataDropdown} setAnalyticsProject={setAnalyticsProject} currentAnalyticsProject={currentAnalyticsProject} currentAnalyticsHolo={currentAnalyticsHolo} setAnalyticsHolo={setAnalyticsHolo}></AnalyticsText>
        <AnalyticsTable data={GetAnalyticsTableData(state.data,currentAnalyticsProject, currentAnalyticsHolo, analyticsData)}></AnalyticsTable>
        <AnalyticsGrid projectData={state.data.projectMap} holoMap={holoDataDropdown} setAnalyticsProject={setAnalyticsProject} currentAnalyticsProject={currentAnalyticsProject} currentAnalyticsHolo={currentAnalyticsHolo} setAnalyticsHolo={setAnalyticsHolo} data={GetAnalyticsGridData(state.data, currentAnalyticsProject, currentAnalyticsHolo, analyticsData)}></AnalyticsGrid>
        </div>
    );
}

// hydrate the states with event data
function getTotalEvents(state){
    var totalEvents = []
    Object.keys(state.data.projectMap).map((projectId) => {
        totalEvents = totalEvents.concat(buildProjectEvents(projectId));
    })

    Object.keys(state.data.holoMap).map((holoId) => {
        state.data.holoMap[holoId].storyIds.map((storyId) => {
            var storyEvents = buildStoryEvents(holoId, storyId);
            totalEvents = totalEvents.concat(storyEvents);
        })
    })
    return totalEvents;
}


function buildStoryEvents(holoId, storyId) {
    var events = [buildVideoViewEvent(holoId, storyId), buildVideoCtaEvent(holoId, storyId), buildAddToCartClicks(holoId, storyId)];
    return events;
}

function buildProjectEvents(projectId){
    var events = [buildProjectSessionDurationEvent(projectId), buildProjectSessionEvent(projectId), buildProjectViewEvent(projectId), buildCheckoutClicksEvent(projectId)];
    return events;
}

function buildProjectViewEvent(projectId){
    return `Project-Views:${projectId}`;
}
function buildProjectSessionEvent(projectId){
    return `Project-Sessions:${projectId}`;
}
function buildProjectSessionDurationEvent(projectId){
    return `Project-Session-Duration:${projectId}`;
}

function buildCheckoutClicksEvent(projectId){
    return `Checkout-Clicks:${projectId}`;
}
function buildVideoViewEvent(holoId, storyId){
    return `Video-Views:${holoId}:${storyId}`;
}
function buildVideoCtaEvent(holoId, storyId) {
    return `CTA-Clicks:${holoId}:${storyId}`;
}
function buildAddToCartClicks(holoId, storyId){
    return `AddToCart-Clicks:${holoId}:${storyId}`
}

export default AnalyticsPage;
