import './holotext.scss'

const HoloText = (props) => {

    return (  
        <div className="page-container">
            <div className='holo-header'>{props.data.projectName}</div>
        </div>
    );
}
 
export default HoloText;