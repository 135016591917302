import './editData.scss'
import React, { useState, useRef, useCallback, useContext, useEffect} from "react";
import { useGlobalState } from '../../../../Hooks/useGlobalState';
import VideoText from "../../../../Components/VideoUpload/VideoText/videotext";
import ThumbnailFile from "../../../../Components/VideoUpload/ThumbnailFile/thumbnailfile";
import VideoCaption from "../../../../Components/VideoUpload/VideoCaption/videocaption";
import RedirectLink from "../../../../Components/VideoUpload/RedirectLink/redirectlink";
import RedirectCap from "../../../../Components/VideoUpload/Redirect Caption/redirectcap";
import PreviewBox from "../../../../Components/VideoUpload/PreviewBox/previewbox";
import SelectProductDropdown from "../../../../Components/VideoUpload/SelectProductDropdown/selectproductdropdown";
import { getUploadVideoURLPromise, getUploadImageURLPromise, addAssetPromise } from '../../../../API/promiseAPI';
import { allGridColumnsSelector } from '@mui/x-data-grid';
const EditVideoContext = React.createContext(null);

const updateStoryEndpoint = "https://api.holovideo.co/updateStory";
const GetRootDataEndpoint = "https://api.holovideo.co/getRoot";

// Get Keys
function GetUploadVideoKey(userId, videoFileName){
    return userId + "/story/" + videoFileName;
}

function GetUploadThumbnailImageKey(userId, thumbnailFileName){
    return userId + "/thumbnail/" + thumbnailFileName;
}

function cleanFileName(file, date) {
    var fname = file.name
    var fileName = fname.substr(0, fname.lastIndexOf('.'));
    var extension = fname.substr(fname.lastIndexOf('.'));
    fileName = fileName.replaceAll(" ", "_").replaceAll(".", "_");
    fileName = fileName + "_" + date + extension
    return new File([file], fileName, {type: file.type});
}

// Handle Uploads
function HandleVideoUpload(userId, videoFile, session){
    if(videoFile == null) return () => Promise.resolve()

    var videoKey = GetUploadVideoKey(userId, videoFile.name);
    return getUploadVideoURLPromise(videoKey, session).then(function(urlJson) {
        var url = JSON.parse(urlJson).url;
        return fetch(url, {
            method: "PUT",
            body: videoFile,
            headers: {"Content-Type": "application/octet-stream"} ,
        })
    }).then(function(_) {
        var metaData = {
            'dateCreated': Date.now(),
            'fileSize': videoFile.size / 1048576        
        }
        return addAssetPromise(userId, videoKey, "video/mp4", videoFile.name, JSON.stringify(metaData), session);
    }).catch((err) => {
        console.error(err);
    })
}

function HandleThumbnailImageUpload(userId, thumbnailImageFile, session){
    if(thumbnailImageFile == null) return () => Promise.resolve()

    var imageKey = "images/" + GetUploadThumbnailImageKey(userId, thumbnailImageFile.name);
    return getUploadImageURLPromise(imageKey, session).then(function(urlJson) {
        var url = JSON.parse(urlJson).url;
        return fetch(url, {
            mode: "cors",
            method: "PUT",
            body: thumbnailImageFile,
            headers: {"Content-Type": "application/octet-stream"},
        })
    }).catch((err) => {
        console.error(err);
    })
}

function UploadVideoForm(props) {
    const {storyType, setStoryType, productId, setProductId, videoCaption, setVideoCaption, redirectCaption, setRedirectCaption, redirectLink, setRedirectLink, thumbnailFile, setThumbnailFile, videoFile, setVideoFile, videoElem, videoAssetElem, thumbnailImgPreview, setThumbnailImgPreview, thumbnailImageSource, assetIdSelected, setAssetIdSelected} = useContext(EditVideoContext);
    const [submitReady, setSubmitReady] = useState(false);
    const [state, dispatch] = useGlobalState();
    const setIsLoading = useCallback(isLoading => dispatch(
        {
            ...state,
            status: {
                ...state.status,
                isLoading : isLoading,
            }
        }
    ), [])

    function HandleSubmit(data, dispatch, setloading){
        setloading(true)
        var videoFile = data.videoFile
        var thumbnailImageFile = data.thumbnailFile;

        var date = Date.now();
        if(videoFile != null) {
            videoFile = cleanFileName(videoFile, date);
        }
        if(thumbnailImageFile != null){
            thumbnailImageFile = cleanFileName(thumbnailImageFile, date);
        }
        return Promise.all([HandleVideoUpload(data.userId, videoFile, state.session), HandleThumbnailImageUpload(data.userId, thumbnailImageFile, state.session)])
        .then((uploadPromises) => {

            var videoAssetId = null;
            if(videoFile){
                videoAssetId = JSON.parse(uploadPromises[0]).assetId;
            }

            else if (assetIdSelected) {
                videoAssetId = assetIdSelected;
            }
            
                const requestBody = {
                    userId : data.userId,
                    holoId: data.holoId,
                    projectId: data.projectId,
                    captionLink : data.redirectCaption,
                    redirectLink : data.redirectLink,
                    captionText : data.videoCaption,
                    videoAssetId : videoAssetId,
                    thumbnailImageKey : thumbnailImageFile ? GetUploadThumbnailImageKey(data.userId, thumbnailImageFile.name) : null,
                    storySettings: data.storySettings,
                    productData: JSON.stringify(data.productData),
                    storyId: data.storyId,
                    storyAnalyticsMetadata: "{}",
                    tags: []
                }
                return fetch(updateStoryEndpoint, {
                    mode: "cors",
                    method: "POST",
                    headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${state.session}`},
                    body: JSON.stringify(requestBody)
                }).then(function(response) {
                    return response.text();
                })
            })
        .then((_) => {
            const requestBody = {
                userId : data.userId,
            }
            return fetch(GetRootDataEndpoint, {
                mode: "cors",
                method: "POST",
                headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${state.session}`},
                body: JSON.stringify(requestBody)
            }).then(function(response) {
                return response.text();
            }).then(function(data) {
                data = JSON.parse(data)
                dispatch({
                    data: {
                    ...data
                    }
                })
            });
        }).then(() => {
            setloading(false);
        })
    }
   
    async function UploadHandleSubmit(){
        var data = {
            userId: state.data.userData.userId,
            holoId: state.currentHolo,
            projectId: state.currentProject,
            videoCaption: videoCaption,
            redirectCaption: storyType == "Shoppable" ? "" : redirectCaption,
            redirectLink: storyType == "Shoppable" ? "" : redirectLink,
            videoFile,
            thumbnailFile,
            productData: productId ? JSON.parse(state.data.userData.products)[productId] : "{}",
            storySettings: JSON.stringify({storyType}),
            storyId: props.data.storyId
        }

        await HandleSubmit(data, dispatch, setIsLoading);
        window.location.reload()
    }

    const videoText = "Edit Story";

    return (
        <div>
            <VideoText videoText={videoText} storyType={storyType} setStoryType={setStoryType}></VideoText>
            <ThumbnailFile assetIdSelected={assetIdSelected} setAssetIdSelected={setAssetIdSelected} thumbnailImageSource={thumbnailImageSource} videoFile={videoFile} setVideoFile={setVideoFile} thumbnailFile={thumbnailFile} setThumbnailFile={setThumbnailFile} videoElem={videoElem} videoAssetElem={videoAssetElem} thumbnailImgPreview={thumbnailImgPreview} setThumbnailImgPreview={setThumbnailImgPreview}></ThumbnailFile> 
            {storyType=="Shoppable" && <SelectProductDropdown productId={productId} setProductId={setProductId}></SelectProductDropdown>}
            <VideoCaption storyType={storyType} videoCaption={videoCaption} setVideoCaption={setVideoCaption}></VideoCaption>
            {storyType=="Basic" && 
                <>
                    <RedirectCap redirectCaption={redirectCaption} setRedirectCaption={setRedirectCaption}></RedirectCap>
                    <RedirectLink redirectLink={redirectLink} setRedirectLink={setRedirectLink}></RedirectLink>
                </>
            }
            <div className="submitbtn" onClick={UploadHandleSubmit} style={{backgroundColor:"#FDA451", color:"#000000", cursor:"pointer", pointerEvents:"all"}}>
                Update Story
            </div>
        </div>
    )
}

const EditData = (props) => {

    // Story Type
    const [storyType, setStoryType] = useState(JSON.parse(props.data.storySettings)['storyType'] ? JSON.parse(props.data.storySettings)['storyType'] : "Basic");

    // Basic video form

    const [videoCaption, setVideoCaption] = useState(props.data.captionText);
    const [redirectCaption, setRedirectCaption] = useState(props.data.captionLink)
    const [redirectLink, setRedirectLink] = useState(props.data.redirectLink);

    // used for previewing videos and elements
    const [thumbnailImageSource, setThumbnailImageSource] = useState(props.data.fullThumbnailImageSource);
    const [videoSource, setVideoSource] = useState(props.data.videoSource);


    const [thumbnailFile, setThumbnailFile] = useState(null); 
    const [videoFile, setVideoFile] = useState(null); 

    // Shoppable video form
    const [productId, setProductId] = useState(storyType != "Basic" ? JSON.parse(props.data.productData)['id']: null);

    // Preview Elements
    const [thumbnailImgPreview, setThumbnailImgPreview] = useState(null);
    const videoElem = useRef();
    const videoAssetElem = useRef();

    // selected asset id
    const [assetIdSelected, setAssetIdSelected] = useState(null);


    const uploadVideoContextValue = {
        assetIdSelected,
        setAssetIdSelected,
        storyType,
        setStoryType,
        videoCaption,
        setVideoCaption,
        redirectCaption, 
        setRedirectCaption,
        redirectLink,
        setRedirectLink,
        thumbnailImgPreview,
        setThumbnailImgPreview,
        videoElem,
        videoAssetElem,
        productId,
        setProductId,
        thumbnailFile,
        setThumbnailFile,
        videoFile,
        setVideoFile,
        thumbnailImageSource,
        videoSource
    }

    return (
        <EditVideoContext.Provider value={uploadVideoContextValue}>
            <div className="upload-video" style={{gap:"30px", minHeight: "533.5px"}}>
                <PreviewBox context={EditVideoContext}></PreviewBox>
                <UploadVideoForm data={props.data} setFinished={() => {}} setloading={() => {}}/>
            </div>
            
        </EditVideoContext.Provider>
    );
}
 

export default EditData;