
import './analyticstable.scss'
import AnalyticsTableElement from './analyticstableElement';
  
  export default function AnalyticsTable(props) {    

    return (
      <div className="analytics-table">
            <AnalyticsTableElement data={props.data}></AnalyticsTableElement>
      </div>
    );
  }

