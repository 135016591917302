
import './CreateHoloPopup.scss'
import { useCallback, useState } from 'react'
import { useGlobalState } from '../../../Hooks/useGlobalState'
import set_state_data from '../../../Reducers/Data/setData'
import * as amplitude from '@amplitude/analytics-browser';


const CreateHoloPopup = (props) => {

    const [state, dispatch] = useGlobalState();

    const [holoName, setHoloName] = useState("Untitled Holo");

    function createHoloBtn () {
        amplitude.track('Create Holo Btn Clicked');
        props.HandleCreateHolo(holoName)
    }

    return (props.createHoloPopup) ? (
        <div className="create-holo-popup" onClick={() => props.setCreateHoloPopup(false)}>
            <div className="create-holo-popup-inner"onClick={e => {
                // do not close modal if anything inside modal content is clicked
                e.stopPropagation();
              }}>
                <label for="rename-holo">New Holo</label><br></br><br></br>
                <div className='rename-holo-box'>
                <input type="text" onChange={(e) => (setHoloName(e.target.value))}  className="rename-holo" id="rename-holo" name="rename-holo" defaultValue='Untitled Holo'/><br/><br></br>
                </div>
                <button id='save-holo-btn' onClick={() => createHoloBtn()}>Create</button>
            </div>
        </div>
     ): "";
}
 
export default CreateHoloPopup;