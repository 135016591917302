import './EditProjectPopup.scss'
import { useCallback, useState } from 'react'
import CustomizeProject from './CustomizeProject/CustomizeProject';
import DeleteProjectPopup from './DeleteProject/DeleteProjectPopup';
import { useGlobalState } from '../../../../Hooks/useGlobalState';
import { updateProjectPromise, getRootDataPromise } from '../../../../API/promiseAPI';
import ToggleLaunchPopup from './ToggleLaunchPopup/ToggleLaunchPopup';
import * as amplitude from '@amplitude/analytics-browser';

const EditProjectPopup = (props) => {
    const [state, dispatch] = useGlobalState()
    
    var project = state.data.projectMap[state.currentProject]
    var projectSettings = JSON.parse(project.projectSettings)

    const [projectName, setProjectName] = useState(project.projectName);
    const [projectStyle, setProjectStyle] = useState(projectSettings.style)
    const [backgroundProjectColor, setBackgroundProjectColor] = useState(projectSettings.backgroundColor)
    const [outlineProjectColor, setOutlineProjectColor] = useState(projectSettings.outlineColor)
    const [activeOutlineProjectColor, setActiveOutlineProjectColor] =  useState(projectSettings.activeOutlineColor)
    const [positionFloatingStory, setPositionFloatingStory] =  useState(projectSettings.position)
    const [positionLaunchPopup, setPositionLaunchPopup] =  useState(projectSettings.position)
    const [launchPopupStatus, setLaunchPopupStatus] = useState(projectSettings.launchPopupStatus)
    const CustomizeProjectProps = { backgroundProjectColor, setBackgroundProjectColor, outlineProjectColor, setOutlineProjectColor, activeOutlineProjectColor, setActiveOutlineProjectColor, positionFloatingStory, setPositionFloatingStory, positionLaunchPopup, setPositionLaunchPopup};

    const holoPositionOptions = [
        {style: "Carousel", image:"https://d23k6qsajefhq1.cloudfront.net/platform-media/select-project-styles/holo-project-style-carousel.png"},
        {style: "Fixed Stories", image:"https://d23k6qsajefhq1.cloudfront.net/platform-media/select-project-styles/holo-project-style-fixedstories.png"},
        {style: "Floating Stories", image:"https://d23k6qsajefhq1.cloudfront.net/platform-media/select-project-styles/holo-project-style-floatingstories.png"},
        {style: "Launch Popup", image:"https://d23k6qsajefhq1.cloudfront.net/platform-media/select-project-styles/holo-project-style-launchpopup.png"},
    ]
    
    function projectStyleSelected(currentStyle) {
        if( currentStyle === projectStyle) {
            return {
                boxShadow: "0px 0px 0px 2px #FDA451"
            }
        };
    }
    async function HandleProjectSave(){
        const saveProjectStyle = {
            backgroundColor: backgroundProjectColor,
            outlineColor: outlineProjectColor,
            activeOutlineColor: activeOutlineProjectColor,
            style: projectStyle,
            position: positionFloatingStory,
            launchPopupStatus: launchPopupStatus
        }
        await updateProjectPromise(state.currentProject, projectName, saveProjectStyle, state.session)
        .then(function(){
            return getRootDataPromise(state.data.userData.userId, state.session);
        }).then(function(data) {
            data = JSON.parse(data)
            dispatch({
                ...state,
                data: {
                ...data
                },
            })
        }).then(function() {
            props.setEditProjectPopup(false);
        })
        amplitude.track('Save Project Btn Clicked')
    }

    function handleProjectStyle (style) {
        setProjectStyle(style)
        if (style == 'Launch Popup') {
            setLaunchPopupStatus(false)
        }
    }

    return (props.editProjectPopup) ? (
        <div className="edit-project-popup-overlay" onClick={() => props.setEditProjectPopup(false)}>
            <div className="edit-project-popup-container"onClick={e => {
                // do not close modal if anything inside modal content is clicked
                e.stopPropagation();
                }}>
                <h2>Edit Store</h2><br></br>

                <label for="rename-project">Store Name</label><br></br><br></br>
                <div className='rename-project-input'>
                <input type="text" onChange={(e) => (setProjectName(e.target.value))} id="rename-project" name="rename-project" placeholder='Untitled Project' defaultValue={projectName}/>
                </div><br></br><br></br>

                <span id="edit-project-style-header">Store Style</span><br></br>

                <div id="edit-project-style-grid">
                {holoPositionOptions.map(function(option){
                        return (
                            <div>
                            <span id="project-style-title">{option.style}</span><br></br><br></br>
                            <img id="project-style-image" src={option.image} style={projectStyleSelected(option.style)} onClick={()=> handleProjectStyle(option.style)}/> 
                            </div>
                        )
                    })
                }
                </div>

                <CustomizeProject {...CustomizeProjectProps} projectStyle={projectStyle}></CustomizeProject>

                {projectStyle != "Launch Popup" && <div><div id="launch-popup-header">Set Launch Popup</div><br></br>
                <div id="launch-popup-settings">
                <ToggleLaunchPopup setPositionFloatingStory={setPositionFloatingStory} setLaunchPopupStatus={setLaunchPopupStatus} launchPopupStatus={launchPopupStatus}></ToggleLaunchPopup>
                <span>Show Launch Popup on page load.</span>
                </div></div>}
                
                <div id="store-description">
                    <span style={{lineHeight:"24px"}}>What is a Store?</span><br></br>
                    <span style={{fontWeight:"300", fontSize:"12px", lineHeight:"12px"}}>Each Store is made up of Holos that contain short video stories. These can be embedded on any page in the style of your choice.</span>
                </div>  
                <button id='save-project-btn' onClick={() => HandleProjectSave() }>Save Store</button><br></br><br></br>
                

            </div>
        </div>
     ): "";
}
 
export default EditProjectPopup;