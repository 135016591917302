const getRootDataEndpoint = "https://api.holovideo.co/getRoot";


const deleteHoloEndpoint ="https://api.holovideo.co/deleteHolo";
const deleteStoryEndpoint ="https://api.holovideo.co/deleteStory";
const updateStoryEndpoint = "https://api.holovideo.co/updateStory";
const updateHoloEndpoint = "https://api.holovideo.co/updateHolo"
const updateHoloStoryIds = "https://api.holovideo.co/updateHoloStoryIds"
const getBatchEventsEndpoint = "https://api.holovideo.co/getBatchEvents"
const getUserIdEndpoint = "https://api.holovideo.co/getUserId";
const addUserEndpoint = "https://api.holovideo.co/addUser";

const addHoloEndpoint = "https://api.holovideo.co/addHolo";

const addProjectEndpoint = "https://api.holovideo.co/addProject"
const deleteProjectEndpoint = "https://api.holovideo.co/deleteProject";
const updateProjectEndpoint = "https://api.holovideo.co/updateProject";
const updateProjectHoloIdsEndpoint = "https://api.holovideo.co/updateProjectHoloIds"

const addShopifyAccessCodeEndpoint = "https://api.holovideo.co/addShopifyAccessCode"

const getUploadImageURLEndpoint = "https://api.holovideo.co/getUploadImageURL";
const getUploadVideoURLEndpoint = "https://api.holovideo.co/getUploadVideoURL"
const addAssetEndpoint = "https://api.holovideo.co/addAsset"

function addShopifyAccessCodePromise(shopifyDomain, shopifyAccessCode, userId, sessionToken){
    const requestBody = {
        userId: userId,
        shopifyAccessCode: shopifyAccessCode,
        shopifyDomain: shopifyDomain
    }
    return fetch(addShopifyAccessCodeEndpoint, {
        mode: "cors",
        method: "POST",
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionToken}`},
        body: JSON.stringify(requestBody)
    }).then(function(response) {
        return response.text();
    })
}


function updateProjectPromise(projectId, projectName, projectSettings, sessionToken){
    const requestBody = {
        projectId: projectId,
        projectName: projectName,
        projectSettings: JSON.stringify(projectSettings)
    }
    return fetch(updateProjectEndpoint, {
        mode: "cors",
        method: "POST",
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionToken}`},
        body: JSON.stringify(requestBody)
    }).then(function(response) {
        return response.text();
    })
}


function deleteProjectPromise(userId, projectId, sessionToken){
    const requestBody = {
        userId: userId,
        projectId: projectId,
    }
    return fetch(deleteProjectEndpoint, {
        mode: "cors",
        method: "POST",
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionToken}`},
        body: JSON.stringify(requestBody)
    }).then(function(response) {
        return response.text();
    })
}

function addProjectPromise(userId, projectName, projectSettings, sessionToken){
    const requestBody = {
        userId: userId,
        projectName: projectName,
        projectSettings: JSON.stringify(projectSettings)
    }
    return fetch(addProjectEndpoint, {
        mode: "cors",
        method: "POST",
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionToken}`},
        body: JSON.stringify(requestBody)
    }).then(function(response) {
        return response.text();
    })
}


function deleteHoloPromise(userId, holoId, projectId, sessionToken) {
    const requestBody = {
        userId : userId,
        holoId: holoId,
        projectId: projectId
    }
    return fetch(deleteHoloEndpoint, {
        mode: "cors",
        method: "POST",
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionToken}`},
        body: JSON.stringify(requestBody)
    }).then(function(response) {
        return response.text();
    })
}


function deleteStoryPromise(userId, holoId, storyId, sessionToken) {
    const requestBody = {
        userId : userId,
        holoId: holoId,
        storyId: storyId
    }
    return fetch(deleteStoryEndpoint, {
        mode: "cors",
        method: "POST",
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionToken}`},
        body: JSON.stringify(requestBody)
    }).then(function(response) {
        return response.text();
    })
}

function updateStoryPromise(userId, holoId, storyId, redirectCaption, redirectLink, videoCaption, videoFileName, thumbnailImageFileName, sessionToken) {
    const requestBody = {
        userId : userId,
        holoId: holoId,
        storyId: storyId,
        captionLink : redirectCaption,
        redirectLink : redirectLink,
        captionText : videoCaption,
        videoKey : GetUploadVideoKey(userId, videoFileName),
        thumbnailImageKey : GetUploadThumbnailImageKey(userId, thumbnailImageFileName)
    }
    return fetch(updateStoryEndpoint, {
        mode: "cors",
        method: "POST",
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionToken}`},
        body: JSON.stringify(requestBody)
    }).then(function(response) {
        return response.text();
    })
}

// Get Keys
function GetUploadVideoKey(userId, videoFileName){
    return "holo-videos/" + userId + "/story/" + videoFileName;
}

function GetUploadThumbnailImageKey(userId, thumbnailFileName){
    return userId + "/thumbnail/" + thumbnailFileName;
}

function updateHoloPromise(holoId, holoName, backgroundColor, outlineColor, activeOutlineColor, position, style, launchPopupStatus, sessionToken){
    var holoSettings = JSON.stringify({backgroundColor: backgroundColor, outlineColor: outlineColor, activeOutlineColor: activeOutlineColor, position: position, launchPopupStatus: launchPopupStatus, style: style})
    const requestBody = {
        holoName : holoName,
        holoId: holoId,
        holoSettings: holoSettings
    }
    return fetch(updateHoloEndpoint, {
        mode: "cors",
        method: "POST",
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionToken}`},
        body: JSON.stringify(requestBody)
    }).then(function(response) {
        return response.text();
    })
}


function getRootDataPromise(userId, sessionToken) {
    const requestBody = {
        userId : userId,
    }
    return fetch(getRootDataEndpoint, {
        mode: "cors",
        method: "POST",
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionToken}`},
        body: JSON.stringify(requestBody)
    }).then(function(response) {
        return response.text();
    })
}


function updateHoloStoryIdPromise(holoId, storyIds, sessionToken){
    const requestBody = {
        holoId: holoId,
        storyIds, storyIds
    }
    return fetch(updateHoloStoryIds, {
        mode: "cors",
        method: "POST",
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionToken}`},
        body: JSON.stringify(requestBody)
    }).then(function(response) {
        return response.text();
    })
}

function updateProjectHoloIdsPromise(projectId, holoIds, sessionToken){
    const requestBody = {
        holoIds: holoIds,
        projectId, projectId
    }
    return fetch(updateProjectHoloIdsEndpoint, {
        mode: "cors",
        method: "POST",
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionToken}`},
        body: JSON.stringify(requestBody)
    }).then(function(response) {
        return response.text();
    })
}

function getBatchEventsPromise(events, sessionToken){
    const requestBody = {
        events: events
    }
    return fetch(getBatchEventsEndpoint, {
        mode: "cors",
        method: "POST",
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionToken}`},
        body: JSON.stringify(requestBody)
    }).then(function(response){
        return response.text();
    })
}


function getUserIdPromise(email, sessionToken){
    return fetch(getUserIdEndpoint, {
        mode: "cors",
        method: "POST",
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionToken}`},
        body: JSON.stringify({userEmail: email})
      }).then(function(response) {
        return response.text();
      })
}

function addUserPromise(email, name, sessionToken){
    return fetch(addUserEndpoint, {
        mode: "cors",
        method: "POST",
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionToken}`},
        body: JSON.stringify({userEmail: email, userName: name})
      }).then(function(response) {
        return response.text();
      })
}

function addHoloPromise(userId, projectId, holoName, sessionToken){
    const requestBody = {
        userId : userId,
        projectId: projectId,
        holoName: holoName
    }
    return fetch(addHoloEndpoint, {
        mode: "cors",
        method: "POST",
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionToken}`},
        body: JSON.stringify(requestBody)
    }).then(function(response) {
        return response.text();
    })
}

function getUploadImageURLPromise(imageKey, sessionToken) {
    const requestBody = {
        key: imageKey,
    }
    return fetch(getUploadImageURLEndpoint, {
        mode: "cors",
        method: "POST",
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionToken}`},
        body: JSON.stringify(requestBody)
    }).then(function(response) {
        return response.text();
    })
}
function getUploadVideoURLPromise(videoKey, sessionToken) {
    const requestBody = {
        key: videoKey,
    }
    return fetch(getUploadVideoURLEndpoint, {
        mode: "cors",
        method: "POST",
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionToken}`},
        body: JSON.stringify(requestBody)
    }).then(function(response) {
        return response.text();
    })
}

function addAssetPromise(userId, assetKey, assetType, assetName, metadata, sessionToken){
    const requestBody = {
        userId,
        assetKey,
        assetType,
        assetName,
        metadata,
    }
    return fetch(addAssetEndpoint, {
        mode: "cors",
        method: "POST",
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionToken}`},
        body: JSON.stringify(requestBody)
    }).then(function(response) {
        return response.text();
    })
}

export { addAssetPromise, updateProjectHoloIdsPromise, getUploadVideoURLPromise, getUploadImageURLPromise, addHoloPromise, addUserPromise, getUserIdPromise, updateProjectPromise, deleteProjectPromise, addProjectPromise, getBatchEventsPromise, updateHoloStoryIdPromise, getRootDataPromise, updateHoloPromise, updateStoryPromise, deleteHoloPromise, deleteStoryPromise, addShopifyAccessCodePromise};