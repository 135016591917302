import {Routes, Route, useNavigate} from 'react-router-dom';

function GridElement(props){
    const navigate = useNavigate();

    const HandleOnClick = () => {
        props.setCurrentHolo(props.holoId);
        navigate('/holopage');
    }
    if (props.thumbnails.length==0) {
        return (
        <div className="holo-prev" id={"holo" + props.holoId} onClick={HandleOnClick}>
                {props.holoName}    
                <div className='holo-bar'>
                <div><img id='first-holo-icon' src="https://d23k6qsajefhq1.cloudfront.net/platform-media/add.png" /></div>
                </div>

            </div>
        )
    }
    else {
    return (
            <div className="holo-prev" id={"holo" + props.holoId} onClick={HandleOnClick}>
                <span style={{cursor:"pointer"}}>{props.holoName}</span>
                <div className="holo-bar">
                    
                    {
                        props.thumbnails.slice(0, 5).map(
                            function(link){
                                return (
                                    <div><img src={link} width="30" height="30"className="story-thumbnail"/></div>
                                )
                            }
                        )
                    }           
                </div>
            </div>
    )
    }
}
export default GridElement;