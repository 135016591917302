
import './SignupContainer.scss'
import EmailPassSignUp from "../../Components/SignupElements/UserInfo/emailpasssignup";
import SignupBtn from '../../Components/SignupElements/SignupBtn/signupbtn';
import SignupText from '../../Components/SignupElements/SignupText/signuptext';
import SigninLink from '../../Components/SignupElements/SigninLink/signinlink';
import { useState } from 'react';
import React from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import GoogleSignUp from '../../Components/SignupElements/GoogleSignupBtn/googlesignup';


function SignupContainer(){

    const [loading, setLoading] = useState(false);
    const [nameField, setNameField] = useState("");
    const [emailField, setEmailField] = useState("");
    const [passwordField, setPasswordField] = useState("");
    const [confirmPasswordField, setConfirmPasswordField] = useState("");
    const navigate = useNavigate()


    const handleSignUp = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        if (passwordField !== confirmPasswordField) {
          console.log("Passwords don't match")
          return;
        }
        try {
          await Auth.signUp({
            username: emailField,
            password: confirmPasswordField,
            attributes: {
              email: emailField,
              name: nameField,
            },
          }).then(() => {
            navigate("/verification")
          })
        } catch (error) {
          console.error(error);
        }
        setLoading(false);
      };

    const fields = {
        emailField: emailField,
        setEmailField: setEmailField,
        nameField: nameField,
        setNameField: setNameField,
        passwordField: passwordField,
        setPasswordField: setPasswordField,
        confirmPasswordField: confirmPasswordField, 
        setConfirmPasswordField: setConfirmPasswordField,
        loading: loading,
        setLoading: setLoading
    }

    return (
        <div className="signupContainer">
            <GoogleSignUp></GoogleSignUp>
            <SignupText></SignupText>
            <EmailPassSignUp fields={fields}></EmailPassSignUp>
            <SignupBtn handleSignUp={handleSignUp}></SignupBtn>
            <SigninLink></SigninLink>
        </div>
    );
}

export default SignupContainer;