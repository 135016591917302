import './emailpasssignup.scss'


const EmailPassSignup = (props) => {
    function EditHandler(setFieldFunction, event){
        setFieldFunction(event.target.value);
    }

    return ( 
        <div className="email-pass-signup">

            <div className="name-id-signup">
                <label for="name">Name</label><br/>
                <input type="text" id="name-id"  onChange={(e) => EditHandler(props.fields.setNameField, e)} name="name" placeholder="Full Name"/><br/>
            </div>

            <div className="email-id-signup">
                <label for="email">Work Email</label><br/>
                <input type="text" id="email-id" onChange={(e) => EditHandler(props.fields.setEmailField, e)} name="email" placeholder="name@company.com"/><br/>
            </div>

            <div className="pass-id-signup">
                <label for="pass">Password</label><br/>
                <input type="password" id="pass-id" onChange={(e) => EditHandler(props.fields.setPasswordField, e)} name="pass" placeholder="Password"/>
            </div>
            <span id="pass-parameters">8 character minimum. 1 uppercase, lowercase, number</span>

            <div className="confirm-pass-id-signup">
                <label for="pass">Confirm Password</label><br/>
                <input type="password" id="confirm-pass-id" onChange={(e) => EditHandler(props.fields.setConfirmPasswordField, e)} name="confirm-pass" placeholder="Confirm Password"/><br/><br></br>
            </div>
        </div>
     );
}
 
export default EmailPassSignup;