import SigninContainer from "../Containers/SigninContainer/SigninContainer";
import HeaderBarSignIn from "../Components/SigninElements/HeaderBar/headerbar-signin";
import VerificationContainer from "../Containers/VerifyContainer/VerificationContainer";


const VerificationPage = () => {
    return (  
        <div>
            <HeaderBarSignIn></HeaderBarSignIn>
            <VerificationContainer></VerificationContainer>        
        </div>
    );
}
 
export default VerificationPage;