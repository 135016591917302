import { useState } from 'react';
import './AssetsText.scss'
import AssetsUploadPopup from '../AssetsUploadPopup/AssetsUploadPopup';
import {ReactComponent as Upload} from '../../../Assets/Icons/upload.svg';

const AssetsText = (props) => {

    const [assetPopup, setAssetPopup] = useState(false)

    function changeDropdown(e){
        props.setAnalyticsHolo(e.target.value)
        if(props.currentAnalyticsProject == 0){
            props.setAnalyticsProject(props.holoMap[e.target.value].projectId)
        }
    }

    function changeAssetTypeDropdown(e){
        props.setAssetTypeDisplayed(e.target.value)
    }

    return (
        <div>
        <div id="assets-text-container">
            <div ID="assets-header">Assets</div>
        </div>
        <div id="asset-text-btns">
        <div id="upload-asset-btn" onClick={()=> setAssetPopup(true)}>Upload&nbsp;&nbsp;<Upload id="upload-icon"></Upload></div>
        </div>
         {assetPopup && <AssetsUploadPopup setAssetPopup={setAssetPopup} assetPopup={assetPopup}></AssetsUploadPopup>}
        </div>
        );
  }


export default AssetsText;
