import './googlesignin.scss'
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import * as amplitude from '@amplitude/analytics-browser';
import { useEffect } from 'react';


const GoogleSignIn = () => {

    return ( 
        <div className="googlesignin" onClick={async () => {
                await Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google, customState: "GoogleSignin"})
            }}>
                <img className="navicon" src="https://d23k6qsajefhq1.cloudfront.net/platform-media/googlelogoicon.png"/>Sign In with Google
        </div>
     );
}
 
export default GoogleSignIn;