
import VideoGrid from '../../Components/HoloElements/VideoGrid/videogrid'
import { useState } from 'react';
import EditVideoPopup from '../../Components/HoloElements/VideoGrid/EditVideoPopup/editVideoPopup';

function HoloVideoGrid (props) {

    //set data that will populate in edit data popup
    const [popup, setPopupData] = useState(null);

    //set trigger that will open edit data popup
    const [open, setOpen] = useState(false);

    return (
        <div>
        <VideoGrid data={props.data} setOpen={setOpen} setPopupData = {setPopupData}></VideoGrid>
        <EditVideoPopup holoData={props.data} holobardata={props.holobardata} popup={popup} trigger={open} setTrigger={setOpen} setPopupData={setPopupData}></EditVideoPopup>
        </div>
    )
}

export default HoloVideoGrid;