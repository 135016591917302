import React, { useState } from "react";
import "./ToggleLaunchPopup.scss";

function ToggleLaunchPopup(props) {

  function onToggle () {
    props.setLaunchPopupStatus(!props.launchPopupStatus);
    props.setPositionFloatingStory("left")
  }

  return (
    <label className="toggle-switch">
      <input type="checkbox" checked={props.launchPopupStatus} onChange={() => onToggle()} />
      <span className="switch" />
    </label>
  );
}
export default ToggleLaunchPopup;