import './CustomizeProject.scss'
import { ChromePicker } from 'react-color'
import { useState, useEffect, useRef } from 'react'
import React from 'react'
import { ReactDOM } from 'react'



const CustomizeProject = (props) => {

    const holoPositionOptions = [
        {position: "bottom", image:"https://d23k6qsajefhq1.cloudfront.net/platform-media/custom-holo-position/holo-position-bottom.png"},
        {position: "left", image:"https://d23k6qsajefhq1.cloudfront.net/platform-media/custom-holo-position/holo-position-left.png"},
        {position: "right", image:"https://d23k6qsajefhq1.cloudfront.net/platform-media/custom-holo-position/holo-position-right.png"},
        {position: "top", image:"https://d23k6qsajefhq1.cloudfront.net/platform-media/custom-holo-position/holo-position-top.png"},
    ]

    const launchPopupPosition = [
        {position: "left", image:"https://d23k6qsajefhq1.cloudfront.net/platform-media/custom-holo-position/holo-position-left-launchpopup.png"},
        {position: "right", image:"https://d23k6qsajefhq1.cloudfront.net/platform-media/custom-holo-position/holo-position-right-launchpopup.png"},
    ]

    const projectColors = [
        {colorStyle: "Background", backgroundColor:props.backgroundProjectColor},
        {colorStyle: "Outline", backgroundColor:props.outlineProjectColor},
        {colorStyle: "Active Outline", backgroundColor:props.activeOutlineProjectColor},
    ]

    //color pickers for bkgd, outline, and active outline color styles on holo bar
    const [openBkgdColorPicker, setOpenBkgdColorPicker] = useState(false)
    const [openOutlineColorPicker, setOpenOutlineColorPicker] = useState(false)
    const [openActiveOutlineColorPicker, setOpenActiveOutlineColorPicker] = useState(false)

    function activePosition(currPosition) {
        if( currPosition === props.positionFloatingStory) {
            return {
                opacity: ".4",
            }
        };
    }

    const storyColor = {
        border: "1px solid " + props.backgroundProjectColor,
        boxShadow: "0px 0px 0px 1px " + props.outlineProjectColor
    }
    const storyColorLive = {
        border: "1px solid " + props.backgroundProjectColor,
        boxShadow: "0px 0px 0px 1px " + props.activeOutlineProjectColor
    }

    function colorPicker (currentPicker) {
        if (currentPicker == "Outline") {
            setOpenBkgdColorPicker(false)
            setOpenActiveOutlineColorPicker(false)
            setOpenOutlineColorPicker(!openOutlineColorPicker)
        }
        else if (currentPicker == "Background") {
            setOpenOutlineColorPicker(false)
            setOpenActiveOutlineColorPicker(false)
            setOpenBkgdColorPicker(!openBkgdColorPicker)
        }
        else {
            setOpenBkgdColorPicker(false)
            setOpenOutlineColorPicker(false)
            setOpenActiveOutlineColorPicker(!openActiveOutlineColorPicker)
        }
    }
    
    return (
        <div className="customize-project" >

            {/*select color*/}
            {props.projectStyle != "Launch Popup" && <div>
            <span id="custom-project-color-header">Color</span><br></br><br></br>
            <div className='custom-project-color-container'>
                {projectColors.map(function(option){
                    return (
                        <div id="color-selector-element">
                        <div className="custom-project-color" onClick={() => colorPicker(option.colorStyle)} style={{backgroundColor: option.backgroundColor}}></div>
                        <span className='custom-project-color-subheader'>{option.colorStyle}</span>
                        </div>
                    )
                })}
            
            <div className='colorStoriesPreview' style={{backgroundColor:props.backgroundProjectColor}}>
            {[1,2,3].map(function(index) {
                return (
                <img src="https://d23k6qsajefhq1.cloudfront.net/platform-media/HoloLogoV1.png" style={index==1? storyColorLive : storyColor} className="colorStoriesPreviewElement"/>
                )
            })}
            </div>
            {openBkgdColorPicker && <ChromePicker disableAlpha className='projectColorPicker'  color={props.backgroundProjectColor} onChange={(color) => props.setBackgroundProjectColor(color.hex)}></ChromePicker>}
            {openOutlineColorPicker && <ChromePicker disableAlpha className='projectColorPicker'   color={props.outlineProjectColor} onChange={(color) => {props.setOutlineProjectColor(color.hex)}}></ChromePicker>}
            {openActiveOutlineColorPicker && <ChromePicker disableAlpha className='projectColorPicker' color={props.activeOutlineProjectColor} onChange={(color) => {props.setActiveOutlineProjectColor(color.hex)}}></ChromePicker>}
            </div></div>}

            {/*select position if floating stories style selected*/}
            {props.projectStyle =="Floating Stories" && <div>
            <br></br><span id="custom-project-color-header">Position</span>
            <div className="custom-project-position-container">
            <div className="custom-project-position-grid">
                {holoPositionOptions.map(function(option){
                        return (
                            <img className="customize-project-position-element" style={activePosition(option.position)} src={option.image} onClick={()=> props.setPositionFloatingStory(option.position)}/> 
                        )
                    })
                }
            </div>
            </div>
            </div>}
            
            {/*select position if launch popup style selected*/}
            {props.projectStyle =="Launch Popup" && <div>
            <span id="custom-project-color-header">Position</span>
            <div className="custom-project-position-container">
            <div className="custom-project-position-grid">
                {launchPopupPosition.map(function(option){
                        return (
                            <img className="customize-project-position-element" style={activePosition(option.position)} src={option.image} onClick={()=> props.setPositionFloatingStory(option.position)}/> 
                        )
                    })
                }
            </div>
            </div>
            </div>}

        </div>
    )


}
 
export default CustomizeProject;