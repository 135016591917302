import SignupContainer from "../Containers/SignupContainer/SignupContainer";
import HeaderBarSignUp from "../Components/SignupElements/HeaderBar/headerbar-signin";


const Signup = () => {
    return (  
        <div>
            <HeaderBarSignUp></HeaderBarSignUp>
            <SignupContainer></SignupContainer>        
        </div>
    );
}
 
export default Signup;