import './emailpass.scss'


const EmailPass = (props) => {
    
    function EditHandler(setFieldFunction, event){
        setFieldFunction(event.target.value);
    }
    
    return ( 
        <div className="email-pass">
        <div className="email-id">
        <label for="email">Email</label><br/>
            <input type="text" id="email-id" name="email" onChange={(e) => EditHandler(props.fields.setEmailField, e)} placeholder="name@company.com"/><br/>
        </div>
        <div className="pass-id">
        <label for="pass">Password</label><br/>
            <input type="password" id="pass-id" name="pass" onChange={(e) => EditHandler(props.fields.setPasswordField, e)} placeholder="Password"/><br/><br></br>
        </div>
        </div>
     );
}
 
export default EmailPass;