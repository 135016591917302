import NavBar from "../Components/NavbarElement/navbar";
import HeaderBar from "../Components/NavbarElement/headerbar";
import HoloText from "../Components/HoloElements/HoloText/holotext";
import VideoBtn from "../Components/HoloElements/VideoBtn/videobtn";
import HoloVideoGrid from "../Containers/HoloVideoGrid/HoloVideoGrid";
import { useState } from "react";

import { useGlobalState } from "../Hooks/useGlobalState";
import React, { useEffect } from "react";
import EditHolo from "../Components/HoloElements/EditHolo/EditHoloBtns";
import { Navigate } from "react-router-dom";
import HoloTabs from "../Components/LibraryElements/HoloTabs/HoloTabs";
import EmbedHoloBtn from "../Components/HoloElements/EmbedHolo/embedholobtn";
import PreviewProjectBtn from "../Components/HoloElements/PreviewProject/previewprojectbtn";


function GetHoloPageData(data, currentHolo){
    var holoData = {
        ...data.holoMap[currentHolo]
    }

    holoData.videoGridData = data.holoMap[currentHolo].storyIds.map((storyId) => {
        return {
            holoId: currentHolo,
            storyId: storyId,
            thumbnailUrl: data.storiesMap[storyId].fullThumbnailImageSource,
            views: data.storiesMap[storyId].storyAnalyticsMetadata.views,
            videoCaption: data.storiesMap[storyId].captionText,
            views: data.storiesMap[storyId].storyAnalyticsMetadata.views,
            ctaCaption: data.storiesMap[storyId].captionLink,
            ctaLink: data.storiesMap[storyId].redirectLink,
            videoSource: data.storiesMap[storyId].videoSource
        }

    })
    return holoData
}

function GetHoloTabData(data, projectData){
    return projectData.holoIds.map((holoId, index) => {
        var value = data.holoMap[holoId]
        var keyData = {
            ...value,
            index: index
        }
        return keyData;
    })
}

function HoloPage(props) {
    const [state, dispatch] = useGlobalState();
    const [previewHolo, setPreviewHolo] = useState(false);

    var projectData = state.data.projectMap[state.currentProject];
    var holoTabData = GetHoloTabData(state.data, projectData);
    
    var data = GetHoloPageData(state.data, state.currentHolo)

    if(!state.status.isLoading){
        data = GetHoloPageData(state.data, state.currentHolo);
    }
    const setCurrentProject = React.useCallback(currentProject => dispatch({currentProject}), []);

    return <div>
            <NavBar setCurrentProject={setCurrentProject} currentProject={state.currentProject} navstate={1}/>
            <HeaderBar userData={state.data.userData}/>
            <HoloText data={projectData}></HoloText>
            <VideoBtn></VideoBtn>
            <HoloVideoGrid holobardata={data} data={data.videoGridData}></HoloVideoGrid>
            <EditHolo setPreviewHolo={setPreviewHolo} holoName={data.holoName} holoSettings={data.holoSettings}></EditHolo>
            <HoloTabs data={holoTabData} setCurrentHolo={props.setCurrentHolo}></HoloTabs>
            <EmbedHoloBtn holoSettings={data.holoSettings}></EmbedHoloBtn>
            <PreviewProjectBtn></PreviewProjectBtn>
        </div>
}

function HoloPageContainer(){
    const [state, dispatch] = useGlobalState();
    const setCurrentHolo = React.useCallback(currentHolo => dispatch({currentHolo}), []);
    return state.data.holoMap.hasOwnProperty(state.currentHolo) ? (
        <HoloPage setCurrentHolo={setCurrentHolo}/>
    ): ( <Navigate to="/library" />);
}

export default HoloPageContainer;
