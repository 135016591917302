import React from 'react';
import globalState from './initialGlobalState';

import createPersistedReducer from 'use-persisted-reducer';
const usePersistedReducer = createPersistedReducer('HOLO_GLOBAL_STATE');

const GlobalStateContext = React.createContext(undefined);
const DispatchStateContext = React.createContext(undefined);  

const useGlobalState = () => [
    React.useContext(GlobalStateContext),
    React.useContext(DispatchStateContext)
];


const GlobalStateProvider = ({ children }) => {
    const [state, dispatch] = usePersistedReducer(
      (state, newValue) => ({ ...state, ...newValue }),
      globalState
    );
    return (
      <GlobalStateContext.Provider value={state}>
        <DispatchStateContext.Provider value={dispatch}>
          {children}
        </DispatchStateContext.Provider>
      </GlobalStateContext.Provider>
    );
};

export {useGlobalState, GlobalStateProvider};