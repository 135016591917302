import './logoutpopup.scss'
import {Routes, Route, useNavigate} from 'react-router-dom';
import { Auth } from "aws-amplify";
import { useGlobalState } from '../../../Hooks/useGlobalState';
import globalState from '../../../Hooks/initialGlobalState';

const LogoutPopup = (props) => {
    const navigate = useNavigate();
    const[state, dispatch ] = useGlobalState()
    

    //still need to log out user before navigating to sigin in page
    const HandleOnClick = async () => {
        try {
          localStorage.clear();
          localStorage.clear();

          window.localStorage.clear();
          window.localStorage.clear();


            await Auth.signOut().then(() => {
              navigate("/signin");
                dispatch(globalState);
            })
          } catch (error) {
            console.error(error)
          }
    }

    return  (
        <div className="logout-popup-overlay" onClick={() => props.setTrigger(false)}>
            <div className="logout-popup" onClick={e => {
                // do not close modal if anything inside modal content is clicked
                e.stopPropagation();
              }}>
                <button id="logout-btn" onClick={HandleOnClick}>Log Out</button>
            </div>
              </div>
     )
}

export default LogoutPopup;
