
import './SigninContainer.scss'
import EmailPass from "../../Components/SigninElements/EmailPass/emailpass";
import SignInBtn from "../../Components/SigninElements/SigninBtn/signinbtn";
import SigninText from "../../Components/SigninElements/SigninText/signintext";
import SignupLink from '../../Components/SigninElements/SignupLink/signuplink';
import GoogleSignIn from '../../Components/SigninElements/GoogleSigninBtn/googlesignin';
import { useNavigate } from 'react-router-dom';
import { useState, useCallback } from 'react';
import { Auth } from "aws-amplify";
import { useGlobalState } from '../../Hooks/useGlobalState';
import InvalidLogin from '../../Components/SigninElements/InvalidLogin/invalidlogin';
import * as amplitude from '@amplitude/analytics-browser';

function SigninContainer(){
    const [emailField, setEmailField] = useState("");
    const [passwordField, setPasswordField] = useState("");
    const [state, dispatch] = useGlobalState();
    const [invalidPass, setInvalidPass] = useState(false);


    const navigate = useNavigate();
    
    const fields = {
        setEmailField: setEmailField,
        setPasswordField: setPasswordField
    }

    // after you sign in 
    // then it must pick up on the new changes first before navigating first

    const HandleSubmit = async (e) => {
        e.preventDefault();
        try {
          await Auth.signIn(emailField, passwordField)
            .then(() => {
                amplitude.setUserId(emailField);
                amplitude.track('User Signed In');
                console.log(state.data.userData.userEmail)
                navigate("/stores");
            })
        } catch (error) {
            console.error(error)
            setInvalidPass(true)
        }
    };

    return (  
        <div className="signinContainer">
            <GoogleSignIn></GoogleSignIn>
            <SigninText></SigninText>
            <EmailPass fields={fields}></EmailPass>
            <SignInBtn HandleSubmit={HandleSubmit}></SignInBtn>
            <SignupLink></SignupLink>
            {invalidPass && <InvalidLogin></InvalidLogin>}
        </div>
    );
}

export default SigninContainer;