import { useEffect } from "react";
import './PreviewProjectBox.scss'
import { useGlobalState } from "../../Hooks/useGlobalState";
import { useNavigate } from "react-router-dom";

function PreviewHoloBox (props) {
    useEffect (() => {
        const script = document.createElement("script");
        script.src = "https://d23k6qsajefhq1.cloudfront.net/holovideo.js.gz";
        document.body.appendChild(script);
    });

    const [state, dispatch] = useGlobalState();
    
    var holoSettings = JSON.parse(state.data.holoMap[state.currentHolo].holoSettings)

    function ProjectStyleHeight () {
        if (holoSettings.style=="Carousel") {
            return window.innerHeight;
        }
        else if (holoSettings.style == "Fixed Stories"){
            return "101.2px";
        }
        else {
            return "100%";
        }
    }

    function ProjectStyle () {
        if (holoSettings.style) {
            var projectStyleEmbedstr = "HoloStories" + holoSettings.style + "Div";
            var projectStyleEmbed = projectStyleEmbedstr.replace(/\s+/g, '');
            return projectStyleEmbed
        }
        else {
            var projectStyleEmbedstr = "HoloStoriesCarouselDiv";
            var projectStyleEmbed = projectStyleEmbedstr.replace(/\s+/g, '');
            return projectStyleEmbed
        }
    }

    return (  
        <div className="previewproject">
            <div class={ProjectStyle()} projectId={state.currentProject} holoId={state.currentHolo} style={{position:"relative",width:"100%",height:ProjectStyleHeight()}}><script type="text/javascript" src="https://d23k6qsajefhq1.cloudfront.net/holovideo.js.gz"></script></div>
        </div>
    );

}

export default PreviewHoloBox;