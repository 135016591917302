import { useState } from "react";
import AssetsTable from "../../Components/Assets/AssetsTable/AssetsTable";
import AssetsText from "../../Components/Assets/AssetsText/AssetsText";
import AssetsUploadPopup from "../../Components/Assets/AssetsUploadPopup/AssetsUploadPopup";
import './AssetsContainer.scss'


function AssetsContainer () {
    const [assetTypeDisplayed, setAssetTypeDisplayed] = useState(0)
    return (
        <div id="AssetsContainer">
        <AssetsText setAssetTypeDisplayed={setAssetTypeDisplayed} assetTypeDisplayed={assetTypeDisplayed}></AssetsText>
        <AssetsTable assetTypeDisplayed={assetTypeDisplayed}></AssetsTable>
        </div>
    )
}
    
export default AssetsContainer;