
import './HoloTabs.scss'
import {Routes, Route, useNavigate} from 'react-router-dom';
import { useGlobalState } from '../../../Hooks/useGlobalState';

const HoloTabs = (props) => {

    const navigate = useNavigate();
    const [state, dispatch] = useGlobalState();

    const HandleOnAllHolosClick = () => {
        navigate('/stores');                    
        }


    return ( 
        <div id="holo-tabs-container">
        <hr id="holo-tab-line"></hr>
        <div className="holo-tabs-grid">
            <span className="holo-tab" style={window.location.pathname=="/stores" ? {color:"#000"} : {color:"#828282"}} onClick={HandleOnAllHolosClick}>All Holos<hr style={window.location.pathname=="/stores" ? {display:"block"} : {display:"none"}} id="holo-tab-selected-line"></hr></span>
            {
                props.data.map(function(holotab){

                    const HandleOnClick = () => {
                    props.setCurrentHolo(holotab.holoId);
                    navigate('/holopage');                    
                    }


                    const activeHoloTab = {
                        color:"#000"
                    };

                    const nonActiveHoloTab = {
                        color:"#828282"
                    };
                    
                    return (
                        <div className="holo-tab" style={window.location.pathname=="/holopage" && state.currentHolo==holotab.holoId ? {color:"#000"} : {color:"#828282"}} id={"holo" + holotab.holoId} onClick={HandleOnClick}>
                            {holotab.holoName}
                            <hr style={window.location.pathname=="/holopage" && state.currentHolo==holotab.holoId ? {display:"block"} : {display:"none"}} id="holo-tab-selected-line"></hr>
                        </div>
                    )
                })
            }
        </div>
        
        </div>
     );
}
 
export default HoloTabs;