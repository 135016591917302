import './embedpopup.scss'
import { BiCodeAlt } from "react-icons/bi";
import { useState } from 'react';
import { useGlobalState } from '../../../Hooks/useGlobalState';

const Popup = (props) => {
    const [state, dispatch] = useGlobalState();
    
    var project = state.data.projectMap[state.currentProject]
    var projectSettings = JSON.parse(project.projectSettings)

    var projectStyleEmbedstr = "HoloStories" + projectSettings.style + "Div";
    var projectStyleEmbed = projectStyleEmbedstr.replace(/\s+/g, '');

    function ProjectStyleHeight () {
        if (projectSettings.style=="Carousel") {
            return "450px";
        }
        else if (projectSettings.style == "Fixed Stories"){
            return "101.2px";
        }
        else {
            return "0px";
        }
    }

    var jsEmbed = `<div class="${projectStyleEmbed}" projectId="${state.currentProject}" style="position:relative;width:100%;height:${ProjectStyleHeight()};"><script type="text/javascript" src="https://d23k6qsajefhq1.cloudfront.net/holovideo.js.gz"></script></div>`
    var bodyString = "</body>"

    const [copiedState, setCopiedState] = useState(false);

    function CopyCode () {
        navigator.clipboard.writeText(jsEmbed)
        setCopiedState(true);
    }

    return (props.trigger) ? (
        <div className="popup" onClick={() => props.setTrigger(false)}>
            <div className="popup-inner" onClick={e => {
                // do not close modal if anything inside modal content is clicked
                e.stopPropagation();
              }}>
                <h3>Add this Store to your site</h3>
                <p id='popup-text'>Copy and paste this code snippet inside the {bodyString} tags of your page. Follow these step by step <a href='https://holocommerce.gitbook.io/get-started/get-started/embed-your-video-store' target="_blank" rel="noopener noreferrer">instructions</a> for embedding to Shopify.</p>
                <textarea defaultValue={jsEmbed}/>
                <button id="copy-code-btn" onClick={CopyCode} style={copiedState? {opacity: ".6"}: {backgroundColor: "#000000"}}>{copiedState ? "Copied" : "Copy Code"}&nbsp;<BiCodeAlt className="embed-icon"/></button>
                {props.children}
            </div>
        </div>
     ): "";
}
 
export default Popup;