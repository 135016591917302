import { useState, createContext, useReducer, useContext } from 'react';
import React from 'react';

const data = {}
data["userData"] = {
  userId : null
};
data["projectMap"] = {};
data["holoMap"] = {};
data["storiesMap"] = {};

var globalState = {
    currentHolo: null,
    currentProject: null,
    data: data,
    isLoggedIn: false,
    status: {
      isLoading: false,
    },
    holoStyle: {
      bkgdColor: "#A7A7A7",
      outlineColor: "#ffffff",
    }
}
// ALWAYS do localStorage.clear()

export default globalState;
