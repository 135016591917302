import './delete-popup.scss'

import {deleteHoloPromise, getRootDataPromise} from '../../../API/promiseAPI'
import set_state_data from '../../../Reducers/Data/setData'
import { useGlobalState } from '../../../Hooks/useGlobalState'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

const DeleteHoloPopup = (props) => {
    const [state, dispatch] = useGlobalState();

    const setIsLoading = useCallback(isLoading => dispatch(
        {
            ...state,
            status: {
                ...state.status,
                isLoading : isLoading,
            }
        }
    ), [])

    const navigate = useNavigate();

    async function HandleDelete(userId, holoId) {
        setIsLoading(true);
        await deleteHoloPromise(userId, holoId, state.currentProject, state.session)
        .then(() => {
            return getRootDataPromise(userId, state.session);
        })
        .then((data) => {
            set_state_data(state, dispatch, data);
        })
        .then(() => {
            props.setTrigger(false, () => setIsLoading(false));
        }).then(() => {
            navigate("/stores")
        })
    }

    return (props.trigger) ? (
        <div className="delete-holo-popup"onClick={() => props.setTrigger(false)}>
            <div className="delete-holo-popup-inner"onClick={e => {
                // do not close modal if anything inside modal content is clicked
                e.stopPropagation();
              }}>
                <h3>Are you sure?</h3>
                <text id='delete-holo-text'>Deleting this Holo will erase all its contents</text>
                <div className='delete-btn-container'>
                <button onClick={() => props.setTrigger(false)} id='cancel-btn'>Cancel</button>
                <button onClick={() => HandleDelete(state.data.userData.userId, state.currentHolo)} id='confirm-del-btn'>Delete</button>
                </div>
                {props.children}
            </div>
        </div>
     ): "";
}
 
export default DeleteHoloPopup;