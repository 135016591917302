import './CreateProjectPopup.scss'
import { useState } from 'react'
import { useGlobalState } from '../../../Hooks/useGlobalState'
import { addProjectPromise, getRootDataPromise } from '../../../API/promiseAPI'
import * as amplitude from '@amplitude/analytics-browser';


const CreateProjectPopup = (props) => {

    const [state, dispatch] = useGlobalState();

    const [projectName, setProjectName] = useState("My Store");
    const [projectStyle, setProjectStyle] = useState('Carousel')

    const holoPositionOptions = [
        {style: "Carousel", image:"https://d23k6qsajefhq1.cloudfront.net/platform-media/select-project-styles/holo-project-style-carousel.png"},
        {style: "Fixed Stories", image:"https://d23k6qsajefhq1.cloudfront.net/platform-media/select-project-styles/holo-project-style-fixedstories.png"},
        {style: "Floating Stories", image:"https://d23k6qsajefhq1.cloudfront.net/platform-media/select-project-styles/holo-project-style-floatingstories.png"},
        {style: "Launch Popup", image:"https://d23k6qsajefhq1.cloudfront.net/platform-media/select-project-styles/holo-project-style-launchpopup.png"},
    ]

    function projectStyleSelected(currentStyle) {
        if( currentStyle === projectStyle) {
            return {
                boxShadow: "0px 0px 0px 2px #2FA8FF"
            }
        };
    }

    
    async function HandleClick() {
        const defaultProjectStyle = {
            backgroundColor: "#ffffff",
            outlineColor: "#dddddd",
            activeOutlineColor: "#000000",
            style: projectStyle,
            position: "left",
            launchPopupStatus: false,
        }
        await addProjectPromise(state.data.userData.userId, projectName, defaultProjectStyle, state.session)
        .then(function(){
            return getRootDataPromise(state.data.userData.userId, state.session);
        }).then(function(data) {
            data = JSON.parse(data)
            var lastProject = data.userData.projectIds.at(-1);
            dispatch({
                ...state,
                data: {
                ...data
                },
                currentProject: lastProject
            })
        }).then(function() {
            props.setCreateProjectPopup(false);
        })
        amplitude.track('Create Store Btn Clicked');
    }

    function handleTogglePopupOverlay(){
        if(state.currentProject != null) {
            props.setCreateProjectPopup(false);
        }
    }

    return (props.createProjectPopup) ? (
        <div className="create-project-popup-overlay" onClick={() => handleTogglePopupOverlay()}>
            <div className="create-project-popup-container"onClick={e => {
                // do not close modal if anything inside modal content is clicked
                e.stopPropagation();
              }}>
                <h2>Create New Store</h2><br></br>

                <label for="rename-project">Store Name</label><br></br><br></br>
                <div className='rename-project-input'>
                <input type="text" onChange={(e) => (setProjectName(e.target.value))} id="rename-project" name="rename-project" placeholder='My Store' defaultValue={projectName}/>
                </div><br></br><br></br>

                <span id="select-project-style-header">Store Style <span style={{fontWeight:"300"}}>(You can edit this later)</span> </span><br></br><br></br>

                <div className="select-project-style-grid">
                {holoPositionOptions.map(function(option){
                        return (
                            <div>
                            <span id="project-style-title">{option.style}</span><br></br><br></br>
                            <img id="project-style-image" src={option.image} style={projectStyleSelected(option.style)} onClick={()=> setProjectStyle(option.style)}/> 
                            </div>
                        )
                    })
                }
                </div>
                <div id="store-description">
                    <span style={{lineHeight:"24px"}}>What is a Store?</span><br></br>
                    <span style={{fontWeight:"300", fontSize:"12px", lineHeight:"12px"}}>Each Store is made up of Holos that contain short video stories. These can be embedded on any page in the style of your choice.</span>
                </div>  
                <button id='create-project-btn' onClick={() => HandleClick()}>Create Store</button>
            </div>
        </div>
     ): "";
}
 
export default CreateProjectPopup;