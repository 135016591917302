import VideoText from "../../Components/VideoUpload/VideoText/videotext";
import ThumbnailFile from "../../Components/VideoUpload/ThumbnailFile/thumbnailfile";
import VideoCaption from "../../Components/VideoUpload/VideoCaption/videocaption";
import RedirectLink from "../../Components/VideoUpload/RedirectLink/redirectlink";
import RedirectCap from "../../Components/VideoUpload/Redirect Caption/redirectcap";
import PreviewBox from "../../Components/VideoUpload/PreviewBox/previewbox";
import UploadVideoContext from './context';
import React, { useState, useRef, useEffect, useContext, useCallback } from "react";
import { useGlobalState } from "../../Hooks/useGlobalState";
import './uploadvideo.scss'
import SelectProductDropdown from "../../Components/VideoUpload/SelectProductDropdown/selectproductdropdown";
import { getUploadVideoURLPromise, getUploadImageURLPromise, addAssetPromise} from '../../API/promiseAPI';
import * as amplitude from '@amplitude/analytics-browser';


const PostAddStoryEndpoint = "https://api.holovideo.co/addStory";
const GetRootDataEndpoint = "https://api.holovideo.co/getRoot";

// Get Keys
function GetUploadVideoKey(userId, videoFileName){
    return userId + "/story/" + videoFileName;
}

function GetUploadThumbnailImageKey(userId, thumbnailFileName){
    return userId + "/thumbnail/" + thumbnailFileName;
}

function cleanFileName(file, date) {
    var fname = file.name
    var fileName = fname.substr(0, fname.lastIndexOf('.'));
    var extension = fname.substr(fname.lastIndexOf('.'));
    fileName = fileName.replaceAll(" ", "_").replaceAll(".", "_");
    fileName = fileName + "_" + date + extension
    return new File([file], fileName, {type: file.type});
}

// Handle Uploads
function HandleVideoUpload(userId, videoFile, session){
    if(videoFile == null) return () => Promise.resolve()

    var videoKey = GetUploadVideoKey(userId, videoFile.name);
    return getUploadVideoURLPromise(videoKey, session).then(function(urlJson) {
        var url = JSON.parse(urlJson).url;
        return fetch(url, {
            method: "PUT",
            body: videoFile,
            headers: {"Content-Type": "application/octet-stream"} ,
        })
    }).then(function(_) {
        var metaData = {
            'dateCreated': Date.now(),
            'fileSize': videoFile.size / 1048576        
        }
        return addAssetPromise(userId, videoKey, "video/mp4", videoFile.name, JSON.stringify(metaData), session);
    }).catch((err) => {
        console.error(err);
    })
}

function HandleThumbnailImageUpload(userId, thumbnailImageFile, session){
    if(thumbnailImageFile == null) return () => Promise.resolve()

    var imageKey = "images/" + GetUploadThumbnailImageKey(userId, thumbnailImageFile.name);
    return getUploadImageURLPromise(imageKey, session).then(function(urlJson) {
        var url = JSON.parse(urlJson).url;
        return fetch(url, {
            mode: "cors",
            method: "PUT",
            body: thumbnailImageFile,
            headers: {"Content-Type": "application/octet-stream"},
        })
    }).catch((err) => {
        console.error(err);
    })
}

function UploadVideoForm(props) {
    const {storyType, setStoryType, productId, setProductId, videoCaption, setVideoCaption, redirectCaption, setRedirectCaption, redirectLink, setRedirectLink, thumbnailFile, setThumbnailFile, videoFile, setVideoFile, videoElem, videoAssetElem, thumbnailImgPreview, setThumbnailImgPreview, assetIdSelected, setAssetIdSelected} = useContext(UploadVideoContext);
    const [submitReady, setSubmitReady] = useState(false);
    const [state, dispatch] = useGlobalState();

    useEffect(() => {
        if(assetIdSelected != null && thumbnailFile != null || videoFile != null && thumbnailFile != null){
            setSubmitReady(true);
        } 
        else setSubmitReady(false)
    }, [videoFile, thumbnailFile, assetIdSelected])


    const setIsLoading = useCallback(isLoading => dispatch(
        {
            ...state,
            status: {
                ...state.status,
                isLoading : isLoading,
            }
        }
    ), [])


    async function HandleSubmit(data, dispatch, setFinished, setloading){
        /*
            data={
                userId
                projectId
                holoId
                redirectCaption
                redirectLink
                videoCaption
                videoFile
                thumbnailFile
                productData
                storySettings
            }
        */
        var videoFile = data.videoFile;
        var thumbnailImageFile = data.thumbnailFile;

        var date = Date.now();
        if(videoFile != null) {
            videoFile = cleanFileName(videoFile, date);
        }
        if(thumbnailImageFile != null){
            thumbnailImageFile = cleanFileName(thumbnailImageFile, date);
        }
        setIsLoading(true);
        await Promise.all([HandleVideoUpload(data.userId, videoFile, state.session), HandleThumbnailImageUpload(data.userId, thumbnailImageFile, state.session)])
        .then((uploadPromises) => {

                var videoAssetId = null;
                if(videoFile){
                    videoAssetId = JSON.parse(uploadPromises[0]).assetId;
                }
                else if (assetIdSelected) {
                    videoAssetId = assetIdSelected;
                }
                
                const requestBody = {
                    userId : data.userId,
                    projectId: data.projectId,
                    holoId: data.holoId,
                    captionText : data.videoCaption,
                    captionLink : data.redirectCaption,
                    redirectLink : data.redirectLink,
                    videoAssetId : videoAssetId,
                    thumbnailImageKey : GetUploadThumbnailImageKey(data.userId, thumbnailImageFile.name),
                    storySettings: data.storySettings,
                    productData: data.productData
                }
                return fetch(PostAddStoryEndpoint, {
                    mode: "cors",
                    method: "POST",
                    headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${state.session}`},
                    body: JSON.stringify(requestBody)
                })
            })
        .then((_) => {
            const requestBody = {
                userId : data.userId,
            }
            return fetch(GetRootDataEndpoint, {
                mode: "cors",
                method: "POST",
                headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${state.session}`},
                body: JSON.stringify(requestBody)
            }).then(function(response) {
                return response.text();
            }).then(function(data) {
                data = JSON.parse(data)
                dispatch({
                    data: {
                    ...data
                    },
                    status: {
                        ...state.status,
                        isLoading : false,
                    }
                })
            });
        }).then(() => {
            setFinished(true);
            setloading(false);
            amplitude.track('Story Published')
        })
        .catch((err) => {
            console.error(err)
        })
    }
   
    function UploadHandleSubmit(){
        var data = {
            userId: state.data.userData.userId,
            holoId: state.currentHolo,
            projectId: state.currentProject,
            videoCaption,
            redirectCaption,
            redirectLink,
            videoFile,
            thumbnailFile,
            productData: productId ? JSON.stringify(JSON.parse(state.data.userData.products)[productId]) : "{}",
            storySettings: JSON.stringify({storyType})
        }

        if(submitReady){
            amplitude.track('Publish Story Btn Clicked')
            HandleSubmit(data, dispatch, props.setFinished, props.setloading)
        }
    }

    return (
        <div id="UploadVideoForm">
            <VideoText storyType={storyType} setStoryType={setStoryType}></VideoText>
            <ThumbnailFile assetIdSelected={assetIdSelected} setAssetIdSelected={setAssetIdSelected} videoFile={videoFile} setVideoFile={setVideoFile} thumbnailFile={thumbnailFile} setThumbnailFile={setThumbnailFile} videoElem={videoElem} videoAssetElem={videoAssetElem} thumbnailImgPreview={thumbnailImgPreview} setThumbnailImgPreview={setThumbnailImgPreview}></ThumbnailFile> 
            {storyType=="Shoppable" && <SelectProductDropdown productId={productId} setProductId={setProductId}></SelectProductDropdown>}
            <VideoCaption storyType={storyType} videoCaption={videoCaption} setVideoCaption={setVideoCaption}></VideoCaption>
            {storyType=="Basic" && 
                <>
                    <RedirectCap redirectCaption={redirectCaption} setRedirectCaption={setRedirectCaption}></RedirectCap>
                    <RedirectLink redirectLink={redirectLink} setRedirectLink={setRedirectLink}></RedirectLink>
                </>
            }
            <div className="submitbtn" onClick={UploadHandleSubmit} style={submitReady?  {backgroundColor:"#0094FF", color:"#fff", cursor:"pointer", pointerEvents:"all"}:null}>
                Publish Story
            </div>
        </div>
    )
}

const UploadVideo = (props) => {
    // Story Type
    const [storyType, setStoryType] = useState("Basic");

    // Basic video form
    const [videoCaption, setVideoCaption] = useState("")
    const [redirectCaption, setRedirectCaption] = useState("")
    const [redirectLink, setRedirectLink] = useState("");
    const [thumbnailFile, setThumbnailFile] = useState(null); 
    const [videoFile, setVideoFile] = useState(null); 

    // Shoppable video form
    const [productId, setProductId] = useState(null);

    // Asset Id Selection
    const [assetIdSelected, setAssetIdSelected] = useState(null);

    // Preview Elements
    const [thumbnailImgPreview, setThumbnailImgPreview] = useState(null);
    const videoElem = useRef();
    const videoAssetElem = useRef();

    const uploadVideoContextValue = {
        assetIdSelected,
        setAssetIdSelected,
        storyType,
        setStoryType,
        videoCaption,
        setVideoCaption,
        redirectCaption, 
        setRedirectCaption,
        redirectLink,
        setRedirectLink,
        thumbnailImgPreview,
        setThumbnailImgPreview,
        videoElem,
        videoAssetElem,
        productId,
        setProductId,
        thumbnailFile,
        setThumbnailFile,
        videoFile,
        setVideoFile
    }

    return (
        <UploadVideoContext.Provider value={uploadVideoContextValue}>
            <div className="upload-video">
                <PreviewBox context={UploadVideoContext}></PreviewBox> 
                <UploadVideoForm setFinished={props.setFinished} setloading={props.setloading}/>
            </div>
        </UploadVideoContext.Provider>
    );
}

export default UploadVideo;
