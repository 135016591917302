import { useState } from 'react';
import './videocaption.scss'

const VideoCaption = (props) => {
    return ( 
        <div className="video-cap" style={props.storyType=="Shoppable" ? {top:"350px"} : {top:"255px"}}>
            <label for="video-cap">Video Caption <span style={{fontWeight:"200", color:"#717171"}}>(Shown only on carousel thumbnail)</span></label><br/>
            <input style={{width:"100%"}} type="text" value={props.videoCaption} id="caption1" name="video-cap" onChange={(e) => props.setVideoCaption(e.target.value)} placeholder="Video Caption"/><br/>
        </div>
     );
}
 
export default VideoCaption;