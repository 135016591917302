import './progresspopup.scss'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function CircularIndeterminate() {
    return (
      <Box className="progress-circle" sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    );
  }

const ProgressPopup = (props) => {
    if (props.load==true) {
    return (
        <div className="progresspopup">
        <CircularIndeterminate></CircularIndeterminate>
        </div>
      );
    }
    else {
        return;
    }
}
 
export default ProgressPopup;