import './signinbtn.scss'

const SignInBtn = (props) => {
    return ( 
        <div id="signinbtn" onClick={(e) => props.HandleSubmit(e)}>
            Sign In
        </div>
     );
}
 
export default SignInBtn;