
import { DataGrid } from '@mui/x-data-grid';
import './analyticstable.scss'

const columns = [
    { field: 'holoName', headerName: 'Holo', width: 100},
    { field: 'thumbnailImageSource', headerName: 'Video', width: 70, renderCell: (params) => <img src={params.value} width="30" height="30" className="story-thumbnail"/>},
    { field: 'productTitle', headerName: 'Product Title', width: 170 },
    { field: 'captionText', headerName: 'Caption', width: 170 },
    { field: 'views', headerName: 'Video Views', width: 130 },
    { field: 'ctaClicks', headerName: 'CTA Clicks', width: 130 },
    { field: 'addToCartClicks', headerName: 'Add to Cart Clicks', width: 140 },
  ];

  const AnalyticsTableElement = (props) => {
    const rows = (props.data);
    return (
      <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          sx={{
            paddingLeft: 1,
            paddingRight: 1,
            fontWeight: 200,
        }}
        />
     );
  }
  export default AnalyticsTableElement;


