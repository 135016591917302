import './librarytext.scss'

const LibraryText = (props) => {
    return (  
        <div>
            <div className="library-header">{props.projectName} </div>
        </div>
    );
}
 
export default LibraryText;