import { Link } from 'react-router-dom';
import './verifysignuplink.scss'

const VerifySignupLink = () => {
    return (  
        <div className='signuplinkcontainer'>
        Don't have an account? <Link to='/signup'><span id='signuplink'>Sign Up</span></Link>
        </div>
    );
}
 
export default VerifySignupLink;