import './previewprojectbtn.scss'
import { useNavigate } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';

const PreviewProjectBtn = (props) => {

    const navigate = useNavigate();

    function previewBtnClick (){
        navigate('/previewholo')
        amplitude.track('Preview Holo Btn Clicked');
    }

    return ( 
        <div className="preview-project-btn" onClick={()=> previewBtnClick()}>
            Preview
        </div>
     );
}

export default PreviewProjectBtn;