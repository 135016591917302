import './App.css';
import LibraryContainer from './Pages/library';
import HoloPageContainer from './Pages/holopage';
import PreviewPage from './Pages/previewprojectpage';
import Signin from './Pages/signin';
import AnalyticsPage from './Pages/analyticspage';
import Signup from './Pages/signup';
import VerificationPage from './Pages/verificationpage';
import UploadVideoPage from './Pages/uploadvideopage';
import IntegrationsPage from './Pages/integrations';
import PreviewHoloPage from './Pages/previewholopage';
import { Route, Routes } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { GlobalStateProvider, useGlobalState } from './Hooks/useGlobalState';
import { Navigate } from "react-router-dom";
import ProgressPopup from './Components/VideoUpload/ProgressPopup/progresspopup';

import { Auth } from "aws-amplify";
import Amplify from "aws-amplify";
import { COGNITO } from "./Configs/aws";
import * as amplitude from '@amplitude/analytics-browser';
import { addUserPromise, getUserIdPromise, getRootDataPromise } from './API/promiseAPI';
import AssetsPage from './Pages/assetpage';

amplitude.init('b1d65047b1b1d95978df20f4f443efc4');
amplitude.track('Platform Launched');

Amplify.configure({
      aws_project_region: "us-west1",
      aws_cognito_identity_pool_id: "us-west-1:20c016f5-ee9a-4dc9-98e3-4daeb5c2736f",
      aws_cognito_region: COGNITO.REGION,
      aws_user_pools_id: COGNITO.USER_POOL_ID,
      aws_user_pools_web_client_id: COGNITO.APP_CLIENT_ID,
      oauth: {
        domain: 'auth.platform.holovideo.co',
        scope: [
          'phone',
          'email',
          'profile',
          'openid',
          'aws.cognito.signin.user.admin'
        ],
        redirectSignIn: 'https://platform.holovideo.co',
        redirectSignOut: 'https://platform.holovideo.co',
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
      federationTarget: "COGNITO_USER_POOLS"
});

function App() {
  return (
    <GlobalStateProvider>
      <Platform/>
    </GlobalStateProvider>
  )
}

const PrivateRoute = ({ children }) => {
  const [state, dispatch] = useGlobalState();
  const [loading, setLoading] = useState(true);
  const setLoggedIn = useCallback(isLoggedIn => dispatch({isLoggedIn}), [])

  useEffect(() => {
    (async () => {
      let user = null;
      try {
        user = await Auth.currentAuthenticatedUser();
        var session = await Auth.currentSession();
        if (user) {
          if(state.data.userData.userId == null){
            var email = user.attributes.email;
            var name = user.attributes.name;

            amplitude.setUserId(email);
            amplitude.track('User Signed In');

            await getUserIdPromise(email, session.getAccessToken().getJwtToken())
            .then(async (data) => {
              var userJson = JSON.parse(data)
              if(Object.keys(userJson) == 0){
                var addedUserJson = await addUserPromise(email, name, session.getAccessToken().getJwtToken());
                userJson = JSON.parse(addedUserJson);
              } 
              var userId = userJson['userId'];
              return userId;
            })
            .then(async (userId) => {
              await getRootDataPromise(userId, session.getAccessToken().getJwtToken()).then(function(data) {
                data = JSON.parse(data)
                var currentProject = state.currentProject;
                if(currentProject == null && data.userData.projectIds.length > 0){
                    currentProject = data.userData.projectIds[0];
                }
                dispatch({    
                  session: session.getAccessToken().getJwtToken(),              
                  ...state,
                  data: {
                  ...data
                  },
                  currentProject: currentProject,
                  isLoggedIn: true
                })
              });
            })
          } else {
            setLoggedIn(true);
          }
        } else {
          setLoggedIn(false);
        }
      } catch (e) {
        setLoggedIn(false);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <div>
      { loading ? <div></div> : state.isLoggedIn ? children: <Navigate to="/signin" />}
    </div>
  )
}


function Platform() {

  const [state, dispatch] = useGlobalState();
  const [lastRefreshedTime, setLastRefreshedTime] = useState(localStorage.getItem('lastRefreshedTime'));
  
  useEffect(() => {
    if(state.data.userData.userId != null){
      var getDataFunc = async () =>
      {
        if(lastRefreshedTime == null || lastRefreshedTime + 60 <= Date.now()) {
          setLastRefreshedTime(Date.now());
          localStorage.setItem('lastRefreshedTime', Date.now());
          var user = await Auth.currentAuthenticatedUser();
          var session = await Auth.currentSession();

          user.refreshSession(session.getRefreshToken(), async (err, session) => {
            await getRootDataPromise(state.data.userData.userId, session.getAccessToken().getJwtToken())

              .then(async function(data) {
                data = JSON.parse(data)
                var currentProject = state.currentProject;
                if(currentProject == null && data.userData.projectIds.length > 0){
                    currentProject = data.userData.projectIds[0];
                }
                dispatch({                  
                  ...state,
                  data: {
                  ...data
                  },
                  currentProject: currentProject,
                  session: session.getAccessToken().getJwtToken()
                })
              });
          });
        } else {
            await getRootDataPromise(state.data.userData.userId, state.session)
            .then(async function(data) {
              data = JSON.parse(data)
              var currentProject = state.currentProject;
              if(currentProject == null && data.userData.projectIds.length > 0){
                  currentProject = data.userData.projectIds[0];
              }
              dispatch({                  
                ...state,
                data: {
                ...data
                },
                currentProject: currentProject,
              })
            });
        }
      }
      getDataFunc()
    }
  }, [])
  
  
  return (
    <div >
       <ProgressPopup load={state.status.isLoading}></ProgressPopup>
       <Routes>
          <Route exact path='/' element={<PrivateRoute><LibraryContainer/></PrivateRoute>}/>
          <Route path='/holopage' element={<PrivateRoute><HoloPageContainer/> </PrivateRoute>}/>
          <Route path='/assets' element={<PrivateRoute><AssetsPage/> </PrivateRoute>}/>
          <Route path='/uploadvideo' element={<PrivateRoute><UploadVideoPage/> </PrivateRoute>}/>
          <Route path='/stores' element={<PrivateRoute><LibraryContainer/> </PrivateRoute>}/>
          <Route path='/previewproject' element={<PrivateRoute><PreviewPage/> </PrivateRoute>}/>
          <Route path='/previewholo' element={<PrivateRoute><PreviewHoloPage/> </PrivateRoute>}/>
          <Route path='/analytics' element={<PrivateRoute><AnalyticsPage/> </PrivateRoute>}/>
          <Route path="/integrations" element={<PrivateRoute><IntegrationsPage/> </PrivateRoute>} />
          <Route path="/signin" element={<Signin/>} />
          <Route path="/signup" element={<Signup/>} />
          <Route path="/verification" element={<VerificationPage/>} />
      </Routes>
    </div>
  )
}


export default App;