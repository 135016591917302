import './signintext.scss'

const SigninText = () => {
    return (  
        <div>
        <div className="signin-subheader">Sign in to Holo</div>
        </div>
    );
}
 
export default SigninText;