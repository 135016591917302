import './verifyheader.scss'

const VerifyHeader = () => {
    return (  
        <div>
        <div className="signin-subheader">Enter email verification code</div>
        </div>
    );
}
 
export default VerifyHeader;