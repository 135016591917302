import { useGlobalState } from "../Hooks/useGlobalState";
import { useCallback } from "react";
import NavBar from "../Components/NavbarElement/navbar";
import HeaderBar from "../Components/NavbarElement/headerbar";
import IntegrationsContainer from "../Containers/IntegrationsContainer/IntegrationsContainer";

const IntegrationsPage = () => {
    const [state, dispatch] = useGlobalState();
    const setCurrentProject = useCallback(currentProject => dispatch({currentProject}), []);

    return (  
        <div>
            <NavBar setCurrentProject={setCurrentProject} navstate={3} currentProject={state.currentProject}  />
            <HeaderBar/>
            <IntegrationsContainer/>
        </div>
    );
}
 
export default IntegrationsPage;