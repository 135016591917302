import './analyticsgrid.scss'

const AnalyticsGrid = (props) => {

    function changeDropdown(e){
        props.setAnalyticsHolo(e.target.value)
        if(props.currentAnalyticsProject == 0){
            props.setAnalyticsProject(props.holoMap[e.target.value].projectId)
        }
    }

    function changeProjectDropdown(e){
        props.setAnalyticsProject(e.target.value)
        props.setAnalyticsHolo(0);
    }

    return (
        <>
        <div className="analytics-text-container">
        <div className="analytics-header">Analytics</div>
            <select onChange={changeProjectDropdown}  className='dropdown-project' value={props.currentAnalyticsProject}>
                <option value="0">All Stores</option>
                {Object.keys(props.projectData).map((id) => <option value={id}>{props.projectData[id].projectName}</option>)}
            </select>
        </div>
        <div id="analytics-container">
        <div id="analytics-store-summary-grid">
            <div id="analytics-store-summary-prev" >
                <div>
                <span>Store Views</span><br></br>
                <div id="metric-description" style={{color:"#000"}}># of times embedded store was viewed on a page</div>
                </div>
                <div id='store-stat-prev'>{props.data.projectViews}</div>
            </div>
            <div id="analytics-store-summary-prev">
                <div>
                <span>Store Sessions</span><br></br>
                <div id="metric-description" style={{color:"#000"}}># of times embedded store was clicked</div>
                </div>
                <div id='store-stat-prev'>{props.data.projectSessions}</div>
            </div>
            <div id="analytics-store-summary-prev">
                <div>
                <span>Session Duration</span><br></br>
                <div id="metric-description" style={{color:"#000"}}>total watch duration across all sessions (minutes)</div>
                </div>
                <div id='store-stat-prev'>{Math.round(props.data.projectSessionDuration / props.data.projectSessions / 100 * 100) / 100}s</div>
            </div>
            <div id="analytics-store-summary-prev">
                <div>
                <span>Checkout Clicks</span><br></br>
                <div id="metric-description" style={{color:"#000"}}># checkout button clicks on cart screen</div>
                </div>
                <div id='store-stat-prev'>{props.data.checkoutClicks}</div>
            </div>
        </div>
        <select onChange={changeDropdown}  className='dropdown-holo' value={props.currentAnalyticsHolo}>
                <option value="0">All Holos</option>
                {Object.keys(props.holoMap).map((id) => <option value={id}>{props.holoMap[id].holoName}</option>)}
            </select>
        <div className="analytics-grid">
            <div className="analytics-prev" id="holo3">
                <div>
                <div>Video Views </div>
                <div id="metric-description"># of times video story played</div>
                </div>
                <div className='stat-prev'>{props.data.videoViews}</div>
            </div>
            <div className="analytics-prev" id="holo4">
                <div>
                <div>CTA Clicks </div>
                <div id="metric-description"># CTA button clicks on video story</div>
                </div>
                <div className='stat-prev'>{props.data.ctaClicks}</div>
            </div>
            <div className="analytics-prev" id="holo4">
                <div>
                <div>Add to Cart Clicks </div>
                <div id="metric-description"># add to cart button clicks on CTA popup</div>
                </div>
                <div className='stat-prev'>{props.data.addToCartClicks}</div>
            </div>
        </div>
        </div>
        </>
     );
}
 
export default AnalyticsGrid;