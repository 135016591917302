import { useState } from 'react';
import EditData from './editData';
import './editVideoPopup.scss'
import ReactHlsPlayer from 'react-hls-player/dist';
import DeleteVidBtn from './DeleteVideo/deletevidbtn';


const EditVideoPopup = (props) => {


    function showVideo (e) {
        e.target.play()
        e.target.style.opacity = "1";
    }

    function hideVideo (e) {
        e.target.pause()
        e.target.style.opacity = "0";
    }

    const [newVidData, setNewVidData] = useState(false);
    const [newImgData, setNewImgData] = useState(false);
    const [newVid, setNewVid] = useState("");
    const [newImg, setNewImg] = useState("");

    return (props.trigger) ? (
        <div className="edit-popup" onClick={() => props.setTrigger(false)} >
            <div className="edit-popup-inner" onClick={e => {
                // do not close modal if anything inside modal content is clicked
                e.stopPropagation();
              }}>

                <div className='popup-inner-container'>


                    {/* <div className="video-edit-container">
                        {newImgData==false && <img src={props.popup.imgSource} className="thumbnail-edit-prev"/>}
                        {newImgData && <img src={newImg} className="thumbnail-edit-prev"/>}
                        {newVidData==false  && <ReactHlsPlayer className="video-edit-prev" src={props.popup.videoSource} loop muted onMouseOver={showVideo} onMouseOut={hideVideo}/>}
                        {newVidData && <video className="video-edit-prev" src={newVid} loop muted onMouseOver={showVideo} onMouseOut={hideVideo}/>}

                        <div className='HoloSelectionBarPreview' >
                            <img className='HoloSelectionBarImg' src={props.holobardata.videoGridData[0].thumbnailUrl} width="20" height="20"/>
                            <span  className='HoloSelectionBarTxt'>{props.holobardata.holoName}</span>
                        </div>

                        {(props.popup.ctaCaption!="" || props.popup.videoCaption!="") && <div className='CTA' id="cta">
                            {props.popup.videoCaption !="" && <div className='ProductDetails'>
                            <img id="ProductDetailsImg" src={props.popup.imgSource}/>
                            <div className="ProductDetailsTxt">
                            <span>{props.popup.videoCaption}</span><br></br>
                            </div>
                            </div>}
                            {props.popup.ctaCaption!="" && <button className="ctaButtonPrev">{props.popup.ctaCaption}&nbsp;</button>}
                        </div>}
                    </div> */}
                </div>




                <EditData data={props.popup}></EditData>
                <DeleteVidBtn data={props.popup} setTrigger={props.setTrigger}></DeleteVidBtn>
            </div>
        </div>
     ): "";
}
 
export default EditVideoPopup;