import { useState } from 'react';
import './analyticstext.scss'

const AnalyticsText = (props) => {

    function changeDropdown(e){
        props.setAnalyticsHolo(e.target.value)
        if(props.currentAnalyticsProject == 0){
            props.setAnalyticsProject(props.holoMap[e.target.value].projectId)
        }
    }

    function changeProjectDropdown(e){
        props.setAnalyticsProject(e.target.value)
        props.setAnalyticsHolo(0);
    }

    return (
        <div>
        </div>
        );
  }


export default AnalyticsText;
