import { useState } from 'react';
import './EditProjectBtn.scss'
import { MdModeEditOutline } from "react-icons/md";
import * as amplitude from '@amplitude/analytics-browser';
import EditProjectPopup from './EditProjectPopup/EditProjectPopup';
import DeleteProjectPopup from './EditProjectPopup/DeleteProject/DeleteProjectPopup';


const EditProject = (props) => {
    const [deletePopupState, setDeletePopupState] = useState(false);
    const [editProjectPopup, setEditProjectPopup] = useState(false);

    function openEditPopup () {
        amplitude.track('Edit Project Btn Clicked')
        setEditProjectPopup(true)
    }

    //<EditHoloPopup holoName={props.holoName} holoSettings={props.holoSettings} trigger={editpopupstate} setTrigger={setEditPopupState}></EditHoloPopup>
    
    return (
        <div>
        <button onClick={openEditPopup} id='edit-project-btn'><MdModeEditOutline id="edit-project-btn-icon"/>&nbsp;Edit Store</button>
        <img id="delete-project-btn" src="https://d23k6qsajefhq1.cloudfront.net/platform-media/icons8-trash-48.png" onClick={()=>setDeletePopupState(true)}/>
        <DeleteProjectPopup deletePopupState={deletePopupState} setEditProjectPopup={props.setEditProjectPopup} setDeletePopupState={setDeletePopupState}/>
        { editProjectPopup && <EditProjectPopup setEditProjectPopup={setEditProjectPopup} editProjectPopup={editProjectPopup}></EditProjectPopup>}
        </div>
     );
}

export default EditProject;
