import NavBar from "../Components/NavbarElement/navbar";
import HeaderBar from "../Components/NavbarElement/headerbar";
import AnalyticsText from "../Components/Analytics/AnalyticsText/analyticstext";
import AnalyticsGrid from "../Components/Analytics/AnalyticsGrid/analyticsgrid";
import AnalyticsTable from "../Components/Analytics/AnalyticsTable/analyticstable";
import { useGlobalState } from "../Hooks/useGlobalState";
import React, { useEffect, useState } from "react";
import {getBatchEventsPromise} from '../API/promiseAPI';
import * as amplitude from '@amplitude/analytics-browser';
import AssetsText from "../Components/Assets/AssetsText/AssetsText";
import AssetsContainer from "../Containers/AssetsContainer/AssetsContainer";

function AssetsPage() {

    return (
        <div>
            <NavBar navstate={4}/>
            <HeaderBar/>
            <AssetsContainer></AssetsContainer>            
        </div>
    );
}

export default AssetsPage;
