import React, { useRef } from "react";
import ReactHlsPlayer from "react-hls-player/dist";

function VideoContainer(props) {

    var videoRef = useRef();
    //play video preview on mouseover and hide thumbnail image
    function showVideo (e) {
        e.target.play()
        e.target.style.opacity = "1";
    }

    //pause video preview on mouseover and show thumbnail image
    function hideVideo (e) {
        e.target.pause()
        e.target.style.opacity = "0";
    }

    const setOpen = props.setOpen;
    const setPopupData = props.setPopupData;

    function handleClick () {
        setOpen(true);
        props.setPopupData({
            videoSource: props.data.videoSource,
            imgSource: props.data.fullThumbnailImageSource,
            videoCaption:props.data.captionText,
            ctaCaption: props.data.captionLink,
            ctaLink: props.data.redirectLink,
            videoFileName: props.data.videoSource.split('/').at(-1).split('.')[0] + ".mp4",
            imgFileName: props.data.fullThumbnailImageSource.split('/').at(-1),
            storyId: props.data.storyId,
            ...props.data
        })
    }

        return props.data && (<div className="video-container" >
            <img src={props.data.fullThumbnailImageSource}  className="thumbnail-prev"/>
            <ReactHlsPlayer className="video-play" playerRef={videoRef} src={props.data.videoSource} muted loop onMouseOver={showVideo} onMouseOut={hideVideo} onClick={handleClick}/>
            <div className="video-caption">
                {props.data.captionText}
            </div>
        </div>)
}
export default VideoContainer