import CustomizeHolo from './EditHoloCustomize';
import './EditHoloPopup.scss'
import { useCallback, useState } from 'react'
import { useGlobalState } from '../../../Hooks/useGlobalState'
import set_state_data from '../../../Reducers/Data/setData'
import {updateHoloPromise, getRootDataPromise} from '../../../API/promiseAPI'
import DeleteHoloPopup from './delete-popup';
import CustomizeProject from '../../LibraryElements/EditProject/EditProjectPopup/CustomizeProject/CustomizeProject';
import ToggleLaunchPopupHolo from './ToggleLaunchPopupHolo/ToggleLaunchPopupHolo';
import * as amplitude from '@amplitude/analytics-browser';

const EditHoloPopup = (props) => {

    const [state, dispatch] = useGlobalState();

    const [holoName, setHoloName] = useState(props.holoName);

    //color and style of holo - using project in state names so we can use customize popup imported from edit project popup
    const [backgroundProjectColor, setBackgroundProjectColor] = useState(JSON.parse(props.holoSettings)['backgroundColor'])
    const [outlineProjectColor, setOutlineProjectColor] = useState(JSON.parse(props.holoSettings)['outlineColor'])
    const [activeOutlineProjectColor, setActiveOutlineProjectColor] =  useState(JSON.parse(props.holoSettings)['activeOutlineColor'])
    const [positionFloatingStory, setPositionFloatingStory] =  useState(JSON.parse(props.holoSettings)['position'])
    const [launchPopupStatus, setLaunchPopupStatus] = useState(JSON.parse(props.holoSettings)['launchPopupStatus'])
    const [style, setHoloStyle] = useState(JSON.parse(props.holoSettings)['style'])

    // using customize popup imported from edit project popup
    const CustomizeProjectProps = { backgroundProjectColor, setBackgroundProjectColor, outlineProjectColor, setOutlineProjectColor, activeOutlineProjectColor, setActiveOutlineProjectColor, positionFloatingStory, setPositionFloatingStory};

    // style options of holo
    const holoStyleOptions = [
        {style: "Carousel", image:"https://d23k6qsajefhq1.cloudfront.net/platform-media/select-project-styles/holo-project-style-carousel.png"},
        {style: "Fixed Stories", image:"https://d23k6qsajefhq1.cloudfront.net/platform-media/select-project-styles/holo-project-style-fixedstories.png"},
        {style: "Floating Stories", image:"https://d23k6qsajefhq1.cloudfront.net/platform-media/select-project-styles/holo-project-style-floatingstories.png"},
        {style: "Launch Popup", image:"https://d23k6qsajefhq1.cloudfront.net/platform-media/select-project-styles/holo-project-style-launchpopup.png"},
    ]
    
    // show selected active holo style with outline
    function holoStyleSelected(currentStyle) {
        if( currentStyle === style) {
            return {
                boxShadow: "0px 0px 0px 2px #FDA451"
            }
        };
    }


    const setIsLoading = useCallback(isLoading => dispatch(
        {
            ...state,
            status: {
                ...state.status,
                isLoading : isLoading,
            }
        }
    ), [])          

    // save edits to holo
    async function HandleSave(userId, holoId, holoName) {
        setIsLoading(true);
        await updateHoloPromise(holoId, holoName, backgroundProjectColor, outlineProjectColor, activeOutlineProjectColor, positionFloatingStory, style, launchPopupStatus, state.session)
        .then(() => {
            return getRootDataPromise(userId, state.session);
        })
        .then((data) => {
            data = JSON.parse(data)
            dispatch({
                ...state,
                data: data
            })    
        })
        .then(() => {
        
        })
    }

    function handleHoloStyle (style) {
        setHoloStyle(style)
        if (style == 'Launch Popup') {
            setLaunchPopupStatus(false)
        }
    }
    
    return (props.editpopupstate) ? (
        <div className="edit-holo-popup" onClick={() => props.setEditPopupState(false)}>
            <div className="edit-holo-popup-inner"onClick={e => {
                // do not close modal if anything inside modal content is clicked
                e.stopPropagation();
              }}>

                <h2>Edit Holo</h2><br></br>

                <label for="rename-holo">Edit Holo Name</label><br></br><br></br>
                <div className='rename-holo-box'>
                <input type="text" onChange={(e) => (setHoloName(e.target.value))}  className="rename-holo" id="rename-holo" name="rename-holo" defaultValue={holoName}/><br/><br></br>
                </div><br></br>

                <span id="edit-holo-style-header">Holo Style</span><br></br>

                <div id="edit-holo-style-grid">
                {holoStyleOptions.map(function(option){
                        return (
                            <div>
                            <span id="holo-style-title">{option.style}</span><br></br><br></br>
                            <img id="holo-style-image" src={option.image} style={holoStyleSelected(option.style)} onClick={()=> handleHoloStyle(option.style)}/> 
                            </div>
                        )
                    })
                }
                </div><br></br>

                <CustomizeProject {...CustomizeProjectProps} projectStyle={style}></CustomizeProject>

                {style != "Launch Popup" && <div style={{marginTop:"10px"}}>
                <span id="edit-project-style-header">Set Launch Popup</span><br></br><br></br>
                <div id="launch-popup-settings">
                <ToggleLaunchPopupHolo setLaunchPopupStatus={setLaunchPopupStatus} launchPopupStatus={launchPopupStatus}></ToggleLaunchPopupHolo>
                <span>Show Launch Popup on page load.</span>
                </div><br></br></div>}

                <button onClick={async () => {await HandleSave(state.data.userData.userId, state.currentHolo, holoName)}} id='save-holo-btn'>Save</button>
            </div>
        </div>
     ): "";
}
 
export default EditHoloPopup;