import { useState } from "react";
import { Link } from "react-router-dom";
import './uploadfinished.scss'

const UploadFinished = (props) => {

    function refreshPage() {
        window.location.reload(false);
      }

    if (props.finished===true) return ( 
        <div className="upload-finished-overlay">
        <div className="upload-finished-popup">
            <h3 className="upload-finished-txt">This video has been added</h3>
            <Link to="/uploadvideo"><button className="upload-finished-btn" style={{color:"blue"}} onClick={refreshPage}>Add another video</button></Link><br></br>
            <Link to="/holopage"><button className="upload-finished-btn">See all videos</button></Link><br></br>
        </div>
        </div>
     );
}
 
export default UploadFinished;