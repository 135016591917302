import './embedholobtn.scss'
import EmbedHoloPopup from './embedholopopup';
import { useState } from 'react';
import { BiCodeAlt } from "react-icons/bi";
import * as amplitude from '@amplitude/analytics-browser';


const EmbedHoloBtn = (props) => {
    const [open, setOpen] = useState(false);

    function OpenEmpedBtn () {
        setOpen(true);
        amplitude.track('Embed Holo Btn Clicked');
    }
    return (  
        <div>
                <button onClick={OpenEmpedBtn} id='embed-holo-btn'><BiCodeAlt id="embed-holo-icon"/>&nbsp;Embed</button>

                <EmbedHoloPopup holoSettings={props.holoSettings} holoId={props.holoId} trigger={open} setTrigger={setOpen}></EmbedHoloPopup>
        </div>
    );
}
 


export default EmbedHoloBtn ;