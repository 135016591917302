import './redirectlink.scss'


const RedirectLink = (props) => {
    return ( 
        <div className="redirect-link">
        <label for="redirect-link">Call to Action Link</label><br/>
            <input style={{width:"100%"}} value={props.redirectLink} type="text" id="caption1" name="redirect-link" onChange={(e) => props.setRedirectLink(e.target.value)} placeholder="Link URL"/><br/>
        </div>
     );
}
 
export default RedirectLink;