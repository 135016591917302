import './hologrid.scss'
import GridElement from './GridElement/GridElement.js';
import { useGlobalState } from '../../../Hooks/useGlobalState';
import { useState } from 'react';
import CreateHoloPopup from '../HoloButton/CreateHoloPopup';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'; 
import { useEffect } from 'react';
import { addHoloPromise, getRootDataPromise, updateProjectHoloIdsPromise} from '../../../API/promiseAPI';

const HoloGrid = (props) => {

    const [state, dispatch] = useGlobalState();
    const [createHoloPopup, setCreateHoloPopup] = useState(false);
    const [holoIdsOrder, setHoloIdsOrder] = useState(props.data.map((holo) => {return holo.holoId}))

    useEffect (() => {
        const updateddata = props.data.map((holo) => {return holo.holoId})
        setHoloIdsOrder(updateddata)
    }, [props.data]);

    function handleOnDragEnd (result) {
        const items = holoIdsOrder
        const [reorderedItem] = holoIdsOrder.splice(result.source.index,1);
        items.splice(result.destination.index, 0, reorderedItem);

        var projectMapCopy = structuredClone(state.data.projectMap);
        projectMapCopy[state.currentProject].holoIds = items

        dispatch({
            ...state,
            data: {
                ...state.data,
                projectMap: projectMapCopy
            }
        })

        var updateProjectHoloId = async () => {
            await updateProjectHoloIdsPromise(state.currentProject, items, state.session);
        }
        updateProjectHoloId()
        setHoloIdsOrder(items);
    }

    async function HandleClick(holoName){
        addHoloPromise(state.data.userData.userId, state.currentProject, holoName, state.session)
        .then(function(){
            return getRootDataPromise(state.data.userData.userId, state.session)
            .then(function(data) {
                data = JSON.parse(data)
                dispatch({
                    ...state,
                    data: {
                    ...data
                    }
                })
            });
        }).then(() => {
            setCreateHoloPopup(false);
        })
    }

    return ( 
        <div id="holo-grid-container">
        <DragDropContext onDragEnd = {handleOnDragEnd}>
            <Droppable direction="horizontal" droppableId = "holos" >
            {(provided) => (
                <div className="holo-grid" {...provided.droppableProps} ref={provided.innerRef}>
                    <div className="holo-prev-empty" onClick={()=>setCreateHoloPopup(true)}>
                        Create New Holo
                        <div className="holo-bar">  
                        <div><img id='first-holo-icon' src="https://d23k6qsajefhq1.cloudfront.net/platform-media/add.png" /></div>
                        </div>
                    </div>  
                    {
                        props.data.map(function(gridElementData, index){
                            return (
                                <Draggable disableInteractiveElementBlocking  key={gridElementData.holoId} draggableId={gridElementData.holoId} index={index}>
                                    {(provided) => (
                                            <div ref =  {provided.innerRef } {...provided.dragHandleProps}  {...provided.draggableProps}>
                                    <GridElement {...gridElementData} setCurrentHolo={props.setCurrentHolo}></GridElement>
                                    </div>
                                    )}
                                </Draggable>
                            )
                        })
                    }
                    {provided.placeholder}
                    <CreateHoloPopup HandleCreateHolo={HandleClick} setCreateHoloPopup={setCreateHoloPopup} createHoloPopup={createHoloPopup}></CreateHoloPopup>
                </div>
            )}
            </Droppable>
        </DragDropContext>
        <div id="holo-description">
                    <span style={{lineHeight:"24px"}}>What is a Holo?</span><br></br>
                    <span style={{fontWeight:"300", fontSize:"12px", lineHeight:"12px"}}>Each Holo is made up of a group of similar videos (ex. Reviews, Featured). Individual Holos can also be embedded onto pages.</span>
                </div>  
        </div>
     );
    
}
 
export default HoloGrid;