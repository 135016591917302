import './DeleteProjectPopup.scss'

import {deleteProjectPromise, getRootDataPromise} from '../../../../../API/promiseAPI'
import set_state_data from '../../../../../Reducers/Data/setData'
import { useGlobalState } from '../../../../../Hooks/useGlobalState'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

const DeleteProjectPopup = (props) => {
    const [state, dispatch] = useGlobalState();

    const setIsLoading = useCallback(isLoading => dispatch(
        {
            ...state,
            status: {
                ...state.status,
                isLoading : isLoading,
            }
        }
    ), [])

    const navigate = useNavigate();

    async function HandleDelete(userId, projectId) {
        setIsLoading(true);
        await deleteProjectPromise(userId, projectId, state.session)
        .then(() => {
            return getRootDataPromise(userId, state.session);
        })
        .then((data) => {
            data = JSON.parse(data)
            var currentProject = null;
            if(data.userData.projectIds && data.userData.projectIds.length > 0){
                currentProject =  data.userData.projectIds[0];
            }
            dispatch({
                ...state,
                data: data,
                currentProject: currentProject
            })           
        })
        .then(() => {
            props.setDeletePopupState(false)
            props.setEditProjectPopup(false , () => props.setDeletePopupState(false, () => setIsLoading(false)));
        }).then(() => {
            navigate("/stores")
        })
    }

    return (props.deletePopupState) ? (
        <div id="delete-project-popup"onClick={() => props.setDeletePopupState(false)}>
            <div className="delete-project-popup-inner"onClick={e => {
                // do not close modal if anything inside modal content is clicked
                e.stopPropagation();
              }}>
                <h3>Are you sure?</h3>
                <text id='delete-project-text'>Deleting this Project will erase all its contents</text>
                <div className='delete-btn-container'>
                <button onClick={() => props.setDeletePopupState(false)} id='cancel-btn'>Cancel</button>
                <button onClick={() => HandleDelete(state.data.userData.userId, state.currentProject)} id='confirm-del-btn'>Delete</button>
                </div>
                {props.children}
            </div>
        </div>
     ): "";
}
 
export default DeleteProjectPopup;