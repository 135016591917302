import './embedbtn.scss'
import Popup from './embedpopup';
import { useState } from 'react';
import { BiCodeAlt } from "react-icons/bi";
import * as amplitude from '@amplitude/analytics-browser';


const EmbedBtn = (props) => {
    const [open, setOpen] = useState(false);

    function OpenEmpedBtn () {
        setOpen(true);
        amplitude.track('Embed Store Btn Clicked');
    }
    return (  
        <div>
                <button onClick={OpenEmpedBtn} id='embed-project-btn'><BiCodeAlt id="embed-project-icon"/>&nbsp;Embed</button>

                <Popup holoId={props.holoId} trigger={open} setTrigger={setOpen}></Popup>
        </div>
    );
}
 


export default EmbedBtn ;