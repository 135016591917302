import './videogrid.scss'
import {Link} from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'; 
import VideoContainer from './VideoGridElement/VideoGridElement.js';
import { useGlobalState } from '../../../Hooks/useGlobalState';
import { updateHoloStoryIdPromise } from '../../../API/promiseAPI'
import * as amplitude from '@amplitude/analytics-browser';

const VideoGrid = (props) => {

    const [state, dispatch] = useGlobalState();
    // get the ids instead of the actual data.... reset the ids accordingly 

    //set new order for stories on videogrid
    const [storyIdsOrder, setStoryIdsOrder] = useState(props.data.map((story) => {return story.storyId}))

    useEffect (() => {
        const updateddata = props.data.map((story) => {return story.storyId})
        setStoryIdsOrder(updateddata)
    }, [props.data]);

    function handleOnDragEnd (result) {
        const items = storyIdsOrder
        const [reorderedItem] = storyIdsOrder.splice(result.source.index,1);
        items.splice(result.destination.index, 0, reorderedItem);

        var holoMapCopy = structuredClone(state.data.holoMap);
        holoMapCopy[state.currentHolo].storyIds = items

        dispatch({
            ...state,
            data: {
                ...state.data,
                holoMap: holoMapCopy
            }
        })

        var updateHoloStoryId = async () => {
            await updateHoloStoryIdPromise(state.currentHolo, items, state.session);
        }
        updateHoloStoryId()

        setStoryIdsOrder(items);
    }


    //setOpen - function to open edit story popup
    // setPopupData - function to set data to populate in edit story popup when click of video grid element
    const setOpen = props.setOpen;
    const setPopupData = props.setPopupData;
    
        return (
            <>
            <DragDropContext onDragEnd = {handleOnDragEnd}>
                <Droppable direction="horizontal" droppableId = "stories" >
                {(provided) => (
                    <div className="holo-video-grid" {...provided.droppableProps} ref={provided.innerRef}>
                        <Link id="first-video-link" to='/uploadvideo' onClick={() => amplitude.track('Add Story Btn Clicked')}>
                            <div className="first-video-grid">
                                <img  id='first-video-icon' src="https://d23k6qsajefhq1.cloudfront.net/platform-media/add.png" />
                                <button id='first-video-btn'>Add Video</button>
                                <span id="first-video-text">Add new video story</span>
                            </div>
                        </Link>
                        {storyIdsOrder.map((storyId, index) => { 
                                return (
                                    <Draggable disableInteractiveElementBlocking  key={storyId} draggableId={storyId} index={index}>
                                        {(provided) => (
                                            <div ref =  {provided.innerRef } {...provided.dragHandleProps}  {...provided.draggableProps}>
                                                <VideoContainer setPopupData={setPopupData} setOpen={setOpen} data={state.data.storiesMap[storyId]}></VideoContainer>
                                            </div>  
                                        )}
                                    </Draggable>
                                )
                            })
                        }
                        {provided.placeholder}
                    </div>
                )}
                </Droppable>
            </DragDropContext>
            {storyIdsOrder.length<4 && <div id="holo-description">
                <span style={{lineHeight:"24px"}}>What is a Story?</span><br></br>
                <span style={{fontWeight:"300", fontSize:"12px", lineHeight:"12px"}}>Stories can be any short video like product demos, video reviews, brand stories, interviews etc. Each story is added to a specific Holo.</span>
            </div>  }
            </>
        );

}
 
export default VideoGrid;