import { useState } from 'react';
import DeleteHoloPopup from './delete-popup';
import './EditHoloBtns.scss'
import { MdModeEditOutline } from "react-icons/md";
import EditHoloPopup from './EditHoloPopup';
import * as amplitude from '@amplitude/analytics-browser';


const EditHolo = (props) => {
    const [editpopupstate, setEditPopupState] = useState(false);
    const [deletepopupstate, setDeletePopupState] = useState(false);

    function openEditPopup () {
        amplitude.track('Edit Holo Btn Clicked')
        props.setPreviewHolo(false)
        setEditPopupState(true)
    }
    
    return (
        <div>
        <button onClick={()=> openEditPopup()} id='edit-holo-btn'><MdModeEditOutline id="edit-icon"/>&nbsp;Edit Holo</button>
        <img id="trash-icon" src="https://d23k6qsajefhq1.cloudfront.net/platform-media/icons8-trash-48.png" onClick={()=>setDeletePopupState(true)}/>
        { editpopupstate && <EditHoloPopup holoName={props.holoName} holoSettings={props.holoSettings} editpopupstate={editpopupstate} setEditPopupState={setEditPopupState}></EditHoloPopup>}
        <DeleteHoloPopup trigger={deletepopupstate} setTrigger={setDeletePopupState}></DeleteHoloPopup>
        </div>
     );
}

export default EditHolo;
