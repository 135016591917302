import NavBar from "../Components/NavbarElement/navbar";
import HeaderBar from "../Components/NavbarElement/headerbar";
import HoloGrid from "../Components/LibraryElements/HoloGridElements/hologrid";
import LibraryText from "../Components/LibraryElements/LibraryText/librarytext";
import HoloBtn from "../Components/LibraryElements/HoloButton/holobtn";
import { useGlobalState } from "../Hooks/useGlobalState";
import React, { useState } from "react";
import EmbedBtn from "../Components/LibraryElements/EmbedProject/embedbtn";
import EditProject from "../Components/LibraryElements/EditProject/EditProjectBtn";
import PreviewProjectBtn from "../Components/LibraryElements/PreviewProject/previewprojectbtn";
import { useEffect } from "react";
import HoloTabs from "../Components/LibraryElements/HoloTabs/HoloTabs";
import * as amplitude from '@amplitude/analytics-browser';


function GetHoloGridData(data, projectData){
    return projectData.holoIds.map((holoId, index) => {
        var value = data.holoMap[holoId]
        var keyData = {
            ...value,
            index: index
        }
        keyData.thumbnails = value.storyIds.map(id => data.storiesMap[id].thumbnailImageSource)
        return keyData;
    })
}


function Library(props) {
    var projectData = props.data.projectMap[props.currentProject];
    var data = GetHoloGridData(props.data, projectData);

    const [state, dispatch] = useGlobalState();

    return (
        <div>
            <HoloGrid  data={data} setCurrentHolo={props.setCurrentHolo} ></HoloGrid>
            <LibraryText projectName={projectData.projectName}></LibraryText>
            <HoloBtn></HoloBtn>
            <HoloTabs setCurrentHolo={props.setCurrentHolo} data={data}></HoloTabs>
            <EmbedBtn holoId={data.holoId}></EmbedBtn>
            <EditProject holoName={data.holoName} holoSettings={data.holoSettings}></EditProject>
            <PreviewProjectBtn></PreviewProjectBtn>
        </div>
    );
}


function LibraryContainer(){
    const [state, dispatch] = useGlobalState();
    const setCurrentHolo = React.useCallback(currentHolo => dispatch({currentHolo}), []);
    const setCurrentProject = React.useCallback(currentProject => dispatch({currentProject}), []);

    return (
        <div>
            <NavBar setCurrentProject={setCurrentProject} navstate={1} currentProject={state.currentProject}  />
            <HeaderBar/>
            {state.currentProject && <Library currentProject={state.currentProject} setCurrentProject={setCurrentProject} data={state.data} setCurrentHolo={setCurrentHolo} />}

        </div>
    );
}

export default LibraryContainer;
