import HeaderBar from "../Components/NavbarElement/headerbar";
import { useGlobalState } from "../Hooks/useGlobalState";
import { useCallback } from "react";
import NavBar from "../Components/NavbarElement/navbar";
import PreviewHoloBox from "../Containers/PreviewContainer/PreviewHoloBox";

const PreviewHoloPage = () => {

    return (  
        <div>
            <HeaderBar/>
            <PreviewHoloBox></PreviewHoloBox>
        </div>
    );
}
 
export default PreviewHoloPage;