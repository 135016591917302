import './verifyinfo.scss'


const VerifyInfo = (props) => {

    function EditHandler(setFieldFunction, event){
        setFieldFunction(event.target.value);
    }

    return ( 
        <div className="email-pass">
        <div className="email-id">
        <label for="email">Email</label><br/>
            <input type="text" id="email-id" name="email" onChange={(e) => EditHandler(props.fields.setEmailField, e)} placeholder="name@company.com"/><br/>
        </div>
        <div className="pass-id">
        <label for="pass">Verfication Code</label><br/>
            <input type="password" id="pass-id" name="pass" onChange={(e) => EditHandler(props.fields.setCodeField, e)}  placeholder="6 digit code"/><br/><br></br>
        </div>
        </div>
     );
}
 
export default VerifyInfo;