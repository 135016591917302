import './videobtn.scss'
import {Routes, Route, useNavigate} from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';

const VideoBtn = () => {
    const navigate = useNavigate();

    const HandleOnClick = () => {
        amplitude.track('Add Story Btn Clicked')
        navigate('/uploadvideo');
    }

    return (
        <div className="create-video-btn" onClick={HandleOnClick}>
            Add story
        </div>
     );
}

export default VideoBtn;
