import { useState } from 'react';
import './deletevidbtn.scss'
import DeleteVideoPopupContainer from './deletevidpopup';

const DeleteVidBtn = (props) => {
    const [deletepopupstate, setDeletePopupState] = useState(false);
    return (
        <div className='delete-story-btn' >
        <img onClick={() => setDeletePopupState(true)} id="delete-story-icon" src="https://d23k6qsajefhq1.cloudfront.net/platform-media/icons8-trash-48.png"/>
        <DeleteVideoPopupContainer {...props} deletepopupstate={deletepopupstate} setDeletePopupState={setDeletePopupState}></DeleteVideoPopupContainer>
        </div>
     );
}

export default DeleteVidBtn;
