import { useContext, useState } from 'react';
import { useRef } from 'react';
import './previewbox.scss'
import ProductDetails from './ProductDetails/ProductDetails';
import { useGlobalState } from '../../../Hooks/useGlobalState';
import ReactHlsPlayer from 'react-hls-player/dist';

const PreviewBox = (props) => {

    const [productDetails, setProductDetails] = useState(false)
    const [state, dispatch] = useGlobalState()
    var productSelectionDataSample = JSON.parse(state.data.userData.products ? state.data.userData.products : "{}")
    
    const {videoElem, videoAssetElem, thumbnailImgPreview, storyType, productId, redirectCaption, videoCaption, videoFile, thumbnailImageSource, videoSource, assetIdSelected} = useContext(props.context);


    var thumbnailImg = thumbnailImgPreview ? thumbnailImgPreview : thumbnailImageSource ? thumbnailImageSource : null;

    var videoPrevSource = assetIdSelected ? state.data.assetsMap[assetIdSelected].src : videoSource ? videoSource : null;

    return ( 
        <div id="story-upload-preview">
        
        <div id="video-container">
            <div id="video-element">
                <div id="text-preview">
                Upload video to see preview<br/><br/><span style={{fontWeight:"400", fontSize:"12px", color:"#fafafa"}}>(MP4, Less than 50MB, Up to 1 minute, Vertical orientation only)</span></div></div>
                
                {videoFile ? <video src={videoFile? URL.createObjectURL(videoFile) : null} width="299" height="531" ref={videoElem} id="myvideo" autoPlay loop muted controls></video> : videoPrevSource ? <ReactHlsPlayer playerRef={videoAssetElem} id="myassetvideo" src={videoPrevSource} autoPlay={true} loop controls muted width="299" height="531"/> : null}

                <div className='HoloSelectionBarPreview'>
                    <img className='HoloSelectionBarImg' src={thumbnailImg}/>
                    <span  className='HoloSelectionBarTxt'>{state.data.holoMap[state.currentHolo].holoName}</span>
                </div>
                
                {(storyType=="Shoppable" && productId) && 
                    <>
                        {!productDetails && <div id='CTA-uploadvideo' onClick={()=> setProductDetails(true)}>
                            <div className='ProductDetails' style={{height:"70px"}}>
                                <img id="ProductDetailsImg" src={productSelectionDataSample[productId].images[0].src}/>
                                <div className="ProductDetailsTxt">
                                    <span id="ProductTitle">{productSelectionDataSample[productId].title}</span>
                                    <span style={{fontSize:"13px", fontWeight:"300", color:"#F4F4F4"}}>${productSelectionDataSample[productId].variants[0].price}</span><br></br>
                                    <span style={{fontSize:"10px", fontWeight:"600", color:"#fafafa"}}>Shop {'>'}</span>
                                </div>
                            </div>
                        </div>}
                        {productDetails && <ProductDetails setProductDetails={setProductDetails} productId={productId}></ProductDetails>}
                    </>
                }       

                {(storyType=="Basic") && (redirectCaption!="" || videoCaption!="") && <div id='CTA-uploadvideo' style={{backgroundColor:"#00000000"}}>
                    {videoCaption!="" && <div className='ProductDetails'>
                        <img id="ProductDetailsImg" style={{width:"30px", height:"30px", borderRadius:"50px"}} src={thumbnailImg}/>
                        <div className="ProductDetailsTxt" style={{left:"40px", maxWidth:"270px"}}>
                            <span>{videoCaption}</span>
                        </div>
                    </div>}
                    {redirectCaption && 
                            <div id="redirectbtn">{redirectCaption}</div>
                        }
                </div>}
        </div>
        </div>
     );
}
 
export default PreviewBox ;