import './redirectcap.scss'


const RedirectCap = (props) => {
    return ( 
        <div className="redirect-cap">
        <label for="redirect-cap">Call to Action</label><br/>
            <input style={{width:"100%"}} value={props.redirectCaption} type="text" id="caption1" name="redirect-cap" onChange={(e) => props.setRedirectCaption(e.target.value)} placeholder="Call to Action"/><br/>
        </div>
     );
}
 
export default RedirectCap;