import { Link } from 'react-router-dom';
import './signinlink.scss'

const SigninLink = () => {
    return (  
        <div className='signinlinkcontainer'>
        Already have an account? <Link to='/'><span id='signinlink'>Sign In</span></Link>
        </div>
    );
}
 
export default SigninLink;