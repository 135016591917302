import { useEffect } from "react";
import './PreviewProjectBox.scss'
import { useGlobalState } from "../../Hooks/useGlobalState";
import { useNavigate } from "react-router-dom";

function PreviewProjectBox () {
    useEffect (() => {
        const script = document.createElement("script");
        script.src = "https://d23k6qsajefhq1.cloudfront.net/holovideo.js.gz";
        document.body.appendChild(script);
    });

    const [state, dispatch] = useGlobalState();
    
    var project = state.data.projectMap[state.currentProject]
    var projectSettings = JSON.parse(project.projectSettings)

    var projectStyleEmbedstr = "HoloStories" + projectSettings.style + "Div";
    var projectStyleEmbed = projectStyleEmbedstr.replace(/\s+/g, '');

    function ProjectStyleHeight () {
        if (projectSettings.style=="Carousel") {
            return window.innerHeight;
        }
        else if (projectSettings.style == "Fixed Stories"){
            return "101.2px";
        }
        else {
            return "100%";
        }
    }

    return (  
        <div className="previewproject">
            <div class={projectStyleEmbed} projectId={state.currentProject} style={{position:"relative",width:"100%",height:ProjectStyleHeight()}}><script type="text/javascript" src="https://d23k6qsajefhq1.cloudfront.net/holovideo.js.gz"></script></div>
        </div>
    );

}

export default PreviewProjectBox;