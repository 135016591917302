import './thumbnailfile.scss'
import { useEffect, useState } from 'react';
import { useGlobalState } from '../../../Hooks/useGlobalState';
import {ReactComponent as DownArrow} from '../../../Assets/Icons/downarrow.svg'
import { useRef } from 'react';

const ThumbnailFile = (props) => {

    const [thumbnailbtn, setthumbnailbtn] = useState("Generate Thumbnail")
    const [videoDropdown, setVideoDropdown] = useState(false);
    const [state, dispatch] = useGlobalState()

    const videoInputRef = useRef();

    function handleClick () {
        if (props.videoFile || props.assetIdSelected) {
            captureThumbnail();
        }
    }

    console.log(state.data)

    useEffect (() => {
      if (!props.thumbnailFile) {
        setthumbnailbtn("Generate Thumbnail")
      }
    }, [props.thumbnailFile])

    function handleVideoUpload (e) {
      if (props.assetIdSelected) {
        props.setThumbnailImgPreview(null)
        props.setAssetIdSelected(null)
        props.setThumbnailFile(null)
      }
      props.setVideoFile(e.target.files[0])
    }

    function handleAssetSelection (asset) {
    
      if (props.videoFile) {
        videoInputRef.current.value = null;
        props.setVideoFile(null);
        props.setThumbnailFile( null);
        props.setThumbnailImgPreview(null)
      }
      props.setAssetIdSelected(asset.assetId);
      setVideoDropdown(!videoDropdown)
    }

    function captureThumbnail (videoFileName) {
        const canvas = document.createElement("canvas");
        canvas.width = 299;
        canvas.height = 531;
        if (props.videoFile) {
          canvas
          .getContext("2d")
          .drawImage(
            props.videoElem.current,
            0,
            0,
            299,
            531
          );
        }
        else if (props.assetIdSelected) {
        canvas
          .getContext("2d")
          .drawImage(
            props.videoAssetElem.current,
            0,
            0,
            299,
            531
          );
        }
    
        props.setThumbnailImgPreview(canvas.toDataURL("image/jpeg"));
        setthumbnailbtn("Thumbnail Saved")
      };

    useEffect(() => {
      if(props.thumbnailImgPreview && props.videoFile) {
        var videoFileName = props.videoFile.name;
        fetch(props.thumbnailImgPreview).then((res) => res.blob())
        .then((blob) => {
          const NewFile = new File([blob], videoFileName.replace('.','')+".jpeg", {
            type: "image/jpeg"
          });
          props.setThumbnailFile(NewFile);
        });
      }
      else if (props.thumbnailImgPreview && props.assetIdSelected) {
        fetch(props.thumbnailImgPreview).then((res) => res.blob())
        .then((blob) => {
          const NewFile = new File([blob], props.assetIdSelected+".jpeg", {
            type: "image/jpeg"
          });
          props.setThumbnailFile(NewFile);
        });
      }
    }, [props.thumbnailImgPreview, props.videoFile, props.assetIdSelected])

    return (
        <div id="video-selection-container">

          <div style={{width:"270px"}}>
          
            <label for="video-upload">Select From Library or Upload New Video</label><br/>
            <div id="select-video-dropdown" onClick={()=> setVideoDropdown(!videoDropdown)}>
              {!props.assetIdSelected && <span>Select Video from Library</span>}
              {props.assetIdSelected && <div id="video-dropdown-element-selected">
                    <img id="video-dropdown-element-img" src={state.data.assetsMap[props.assetIdSelected].thumbnailSrc}/>
                    <div id="video-dropdown-element-txt">{state.data.assetsMap[props.assetIdSelected].assetName}</div>
              </div>}
              <DownArrow style={{height:"30px"}}/>    
            </div>   
            {videoDropdown && <div id="select-video-dropdown-options">
              {Object.values(state.data.assetsMap).map((asset) => 
                <div id="video-dropdown-element" onClick={() => handleAssetSelection(asset)}>
                  <img id="video-dropdown-element-img" src={asset.thumbnailSrc}/>
                  <div id="video-dropdown-element-txt">{asset.assetName}</div>
                </div>
              )}
            </div>}
            <input ref={videoInputRef} type="file" id="myFile" name="video-upload" accept='video/mp4' onChange={(e) => handleVideoUpload(e)}/><br/>  

            <button id="thumbnail-image-generator-btn" style={thumbnailbtn=="Thumbnail Saved" ? {backgroundColor:"#CFEBFF"}:null} onClick={handleClick}>{thumbnailbtn}</button>
          </div>

          <div>
            <div id="thumbnail-preview-outline">
              {(props.thumbnailFile || props.thumbnailImageSource || props.thumbnailImgPreview) && <img id='thumbnail-preview' src={props.thumbnailImgPreview ? props.thumbnailImgPreview : props.thumbnailImageSource ? props.thumbnailImageSource : null}/>}
            </div>
          </div>
        </div> 
     );
}
 
export default ThumbnailFile;