import './AssetsUploadPopup.scss'
import React, { useEffect } from 'react';
import { useState, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {getUploadVideoURLPromise, addAssetPromise, getRootDataPromise} from '../../../API/promiseAPI'
import { useGlobalState } from '../../../Hooks/useGlobalState';

// Get Keys
function GetUploadVideoKey(userId, videoFileName){
    return userId + "/story/" + videoFileName;
}

function cleanFileName(file) {
    var fname = file.name
    var fileName = fname.substr(0, fname.lastIndexOf('.'));
    var extension = fname.substr(fname.lastIndexOf('.'));
    fileName = fileName.replaceAll(" ", "_").replaceAll(".", "_");
    fileName = fileName + "_" + Date.now() + extension
    return new File([file], fileName, {type: file.type});
}

// Handle Uploads
function HandleVideoUpload(userId, videoFile, session){
    if(videoFile == null) return () => Promise.resolve()

    var videoKey = GetUploadVideoKey(userId, videoFile.name);
    return getUploadVideoURLPromise(videoKey, session).then(function(urlJson) {
        var url = JSON.parse(urlJson).url;
        return fetch(url, {
            method: "PUT",
            body: videoFile,
            headers: {"Content-Type": "application/octet-stream"} ,
        })
    }).then(function(_) {
        var metaData = {
            'dateCreated': Date.now(),
            'fileSize': videoFile.size / 1048576        
        }
        return addAssetPromise(userId, videoKey, "video/mp4", videoFile.name, JSON.stringify(metaData), session);
    }).catch((err) => {
        console.error(err);
    })
}



function AssetsUploadPopup (props) {

    const hiddenFileInput = React.useRef(null);
    const [assetFiles, setAssetFiles] = useState(null)
    const [rows, setRows] = useState([])
    const [state, dispatch] = useGlobalState();

    function handleFileUpload () {
        hiddenFileInput.current.click();
    }

    function handleFileChange (e) {
        const fileUploaded = e.target.files;
        setAssetFiles(fileUploaded)
    }

    const columns = [
        { field: 'name', headerName: 'Name', width: 400},
        { field: 'type', headerName: 'Type', width: 140, },
        { field: 'assetSize', headerName: 'Size', width: 140 },
      ];


    useEffect (() => {
        if (assetFiles) {
            var assetsUploaded = rows
            Object.keys(assetFiles).map(key => {
                const assetData = assetFiles[key]
                var i;
                if (rows.length>0) {
                    var duplicate = false;
                    for (i=0; i<rows.length; i++) {
                        if (assetData.name == rows[i].name) {
                            duplicate = true;
                            alert("File Already Added")
                            return null;
                        }
                    }
                    if (duplicate==false) {
                        assetData['id'] = assetFiles[key].name + assetFiles[key].lastModified;
                        assetData['assetSize'] = Math.round(assetFiles[key].size/1000000 * 100) / 100 + " MB"
                        assetsUploaded = [...assetsUploaded, assetData];
                    }
                }
                else {
                    assetData['id'] = assetFiles[key].name + assetFiles[key].lastModified;
                    assetData['assetSize'] = Math.round(assetFiles[key].size/1000000 * 100) / 100 + " MB"
                    assetsUploaded = [...assetsUploaded, assetData];
                }
            }
            )
            setRows(assetsUploaded)
            setAssetFiles(null)
        }
    }, assetFiles)


    const setIsLoading = useCallback(isLoading => dispatch(
        {
            ...state,
            status: {
                ...state.status,
                isLoading : isLoading,
            }
        }
    ), [])


    async function handleUploadFiles(){
        setIsLoading(true);
        var promises = Object.values(rows).map((file) => {
            var newFile = cleanFileName(file);
            return HandleVideoUpload(state.data.userData.userId, newFile,state.session);
        });
        await Promise.all(promises).then(() => {
            return getRootDataPromise(state.data.userData.userId, state.session);
        })
        .then((data) => {
            data = JSON.parse(data)
            dispatch({
                ...state,
                data: data
            })           
         })
        .then(() => {
            props.setAssetPopup(false);
            setIsLoading(false);
            window.location.reload()
        })
    }

    return (
        (props.assetPopup) &&
        <div id="asset-upload-popup-overlay" onClick={()=>props.setAssetPopup(false)}>
            <div id="asset-upload-popup" onClick={e => {e.stopPropagation();}}>
                <div id="file-dropzone" onClick={handleFileUpload}><span style={{fontWeight:"bold"}}>Click to Add Files</span></div>
                <input type="file" multiple onChange={handleFileChange} ref={hiddenFileInput} style={{display:'none'}} /> 
                {rows.length>0 && <>
                <div id="asset-preview-table"> <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    sx={{
                        paddingLeft: 1,
                        paddingRight: 1,
                        fontWeight: 200,
                    }}>
                </DataGrid></div>
                <div id="asset-popup-btns">
                <button id="cancel-upload-asset-btn" onClick={() => setRows([])}>Cancel</button>
                <button id="submit-upload-asset-btn" onClick={handleUploadFiles}>Upload</button>
                </div>
                </>}
            </div>
        </div>
    )
}

export default AssetsUploadPopup;