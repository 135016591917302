
import { useGlobalState } from '../../../Hooks/useGlobalState';
import './AssetsTable.scss'
  
  export default function AssetsTable(props) {   
    
    const [state, dispatch] = useGlobalState()

    function cleanAssetName(fname){
      var fileName = fname.substr(0, fname.lastIndexOf('.'));
      var extension = fname.substr(fname.lastIndexOf('.'));
      fileName = fileName.substr(0, fileName.lastIndexOf("_"));
      fileName = fileName + extension    
      return fileName;
    }

    function cleanDate(dt){
      return  (dt.getMonth() + 1) + "/" + dt.getDate() +  "/" +dt.getFullYear();
    }

    return (
      <div id="assets-grid">
        {Object.values(state.data.assetsMap).map(function(asset){
            return (
                <div id="asset-prev">
                  <img id="asset-thumbnail" src={asset.thumbnailSrc}></img>
                  <div id="asset-details">
                    <div id="asset-file-name">{cleanAssetName(asset.assetName)}</div>
                    <span>{asset.assetType}</span><br></br>
                    <span>{cleanDate(new Date(JSON.parse(asset.metadata).dateCreated))}</span><br></br>
                  </div>
                </div>
            )
        })}

      </div>
    );
  }

