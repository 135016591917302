import './holobtn.scss'
import { useGlobalState } from '../../../Hooks/useGlobalState';
import * as amplitude from '@amplitude/analytics-browser';
import CreateHoloPopup from './CreateHoloPopup';
import { useState } from 'react';
import { addHoloPromise, getRootDataPromise } from '../../../API/promiseAPI';

const HoloBtn = () => {
    const [state, dispatch] = useGlobalState();
    const [createHoloPopup, setCreateHoloPopup] = useState(false);

    async function HandleClick(holoName){
        addHoloPromise(state.data.userData.userId, state.currentProject, holoName, state.session)
        .then(function(){
            return getRootDataPromise(state.data.userData.userId, state.session)
            .then(function(data) {
                data = JSON.parse(data)
                dispatch({
                    ...state,
                    data: {
                    ...data
                    }
                })
            });
        }).then(() => {
            setCreateHoloPopup(false);
        })
    }


    return (
        <div>
            <div className="create-holo-btn" onClick={()=>setCreateHoloPopup(true)}>
                Create Holo
            </div>
            <CreateHoloPopup HandleCreateHolo={HandleClick} setCreateHoloPopup={setCreateHoloPopup} createHoloPopup={createHoloPopup}></CreateHoloPopup>
        </div>
     );
}

export default HoloBtn;
