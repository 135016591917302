import React, { useState } from "react";
import "./ToggleLaunchPopupHolo.scss";

function ToggleLaunchPopupHolo(props) {

  const onToggle = () => props.setLaunchPopupStatus(!props.launchPopupStatus);
  return (
    <label className="toggle-switch">
      <input type="checkbox" checked={props.launchPopupStatus} onChange={onToggle} />
      <span className="switch" />
    </label>
  );
}
export default ToggleLaunchPopupHolo;