import './headerbar.scss'
import {Link} from 'react-router-dom';
import { useState } from 'react';
import LogoutPopup from './LogoutPopup/logoutpopup';
import {ReactComponent as Settings} from '../../Assets/Icons/settings.svg';


const HeaderBar = (props) => {
    
    const [logout, setLogout] = useState(false);
    return ( 
        <div className="header-bar-user">
            <Link className='headerlink' to="/stores">
            <img id="header-logo" src='https://d23k6qsajefhq1.cloudfront.net/platform-media/holocommerceblacklogo.png'></img>
            </Link>
            <div onClick={() => setLogout(true)} id="profile-btn"><Settings></Settings></div>
            {logout && <LogoutPopup setTrigger={setLogout}></LogoutPopup>}
        </div>
     );
}
 
export default HeaderBar;