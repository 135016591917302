import './signuptext.scss'

const SignupText = () => {
    return (  
        <div>
        <div className="signup-subheader">Try Holo free for 14 days</div><br></br>
        <span id="free-trial-header">Full Access. No credit card required</span>
        </div>
    );
}
 
export default SignupText;