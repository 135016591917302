import HeaderBar from "../Components/NavbarElement/headerbar";
import { useGlobalState } from "../Hooks/useGlobalState";
import { useCallback } from "react";
import NavBar from "../Components/NavbarElement/navbar";
import PreviewProjectBox from "../Containers/PreviewContainer/PreviewProjectBox"

const PreviewPage = () => {
    const [state, dispatch] = useGlobalState();
    const setCurrentProject = useCallback(currentProject => dispatch({currentProject}), []);

    return (  
        <div>
            <HeaderBar/>
            <PreviewProjectBox></PreviewProjectBox>
        </div>
    );
}
 
export default PreviewPage;