import { useState } from 'react';
import './selectproductdropdown.scss'
import { useGlobalState } from '../../../Hooks/useGlobalState';
import {ReactComponent as DownArrow} from '../../../Assets/Icons/downarrow.svg'

const SelectProductDropDown = (props) => {

   const [state, dispatch] = useGlobalState()
   var productSelectionDataSample = JSON.parse(state.data.userData.products ? state.data.userData.products : "{}")

    const [productDropdown, setProductDropdown] = useState(false);

    function checkProductDropdown () {
        if (state.data.userData.products) {
            setProductDropdown(!productDropdown)
        }
        else {
            alert("Adding product data to story requires Shopify Integration. Go to the integrations tab to get set up.")
        }
    }

    function handleProductSelection (item) {
        props.setProductId(item);
        setProductDropdown(!productDropdown);
    }

    return (  
        <div className="select-product-container">

            <span id="select-product-header"> Add Product <span style={{fontWeight:"200", color:"#717171"}}>(Shopify integration required)</span></span><br></br>
            <div id="select-product-dropdown" onClick={()=> checkProductDropdown()}>
                
                {props.productId==null && <span>Select Product</span>}
                {props.productId &&  <div id="select-product-dropdown-selected">
                <img id="select-product-image" src={productSelectionDataSample[props.productId].images[0].src}></img>   
                <span id="select-product-title">{productSelectionDataSample[props.productId].title}</span>
                </div> }
                <DownArrow style={{height:"30px"}}/>
            </div>
            
            {productDropdown && <div id="select-product-dropdown-options">
            {Object.keys(productSelectionDataSample).map((item) => 
                <div id="select-product-dropdown-element" onClick={()=>handleProductSelection(item)}>
                    <img id="select-product-image" src={productSelectionDataSample[item].images[0].src}></img>   
                    <span id="select-product-title">{productSelectionDataSample[item].title}</span>
                </div>
                )}
            </div>}
        </div>
    );
}

export default SelectProductDropDown;