import './invalidlogin.scss'


const InvalidLogin = () => {
    
    return ( 
       <div className='invalid-login'>Wrong email or password</div>
     );
}
 
export default InvalidLogin;