import { useState } from 'react'
import { useGlobalState } from '../../../../Hooks/useGlobalState'
import './ProductDetails.scss'
import { ReactComponent as ChevronRight } from  '../../../../Assets/Icons/chevron-right.svg'
import {ReactComponent as WhiteDownArrow} from '../../../../Assets/Icons/downarrowwhite.svg'



function ProductDetails (props) {

   const [state, dispatch] = useGlobalState()
   var productSelectionDataSample = JSON.parse(state.data.userData.products ? state.data.userData.products : "{}")
    return (
    <div id='ProductDetails-Container-Platform'>
        <div id="ProductDetails-Header-Platform">
            <span  id='ProductDetails-HeaderTxt-Platform'>Shop</span>
            <div  id='ProductDetails-CloseBtn-Platform' onClick={()=> props.setProductDetails(false)}><WhiteDownArrow></WhiteDownArrow></div>
        </div>
        <div id="ProductDetails-ImagesContainer-Platform" >
        <div id="ProductDetails-ImagesBtn-Platform"><ChevronRight></ChevronRight></div>
        <div id="ProductDetails-ImagesGrid-Platform">
                {productSelectionDataSample[props.productId].images.map((data) => {
                    return (
                        <img 
                            id="ProductDetails-ImagesElement-Platform" 
                            src={data.src}
                        />)
                })}
        </div>
        </div>

        <div id="ProductDetails-Title-Platform">{productSelectionDataSample[props.productId].title}</div>
        <span id="ProductDetails-Price-Platform">${productSelectionDataSample[props.productId].variants[0].price}</span>
        <div id="ProductDetails-VariantsContainer-Platform">
        {Object.keys(productSelectionDataSample[props.productId].options).map((item) => 

            <select id="ProductDetails-VariantElement-Platform" name="cars">

            <option>{productSelectionDataSample[props.productId].options[item][item].name}</option>
            {productSelectionDataSample[props.productId].options[item][item].values.map((value) => 
                <option >{value}</option>
            ) }
            </select>
        )}
        </div>
        {productSelectionDataSample[props.productId].description && <div id="ProductDetails-Description-Platform">{productSelectionDataSample[props.productId].description} <span style={{fontWeight:"bold"}}>more</span></div>}
        <button id="ProductDetails-AddToCartBtn-Platform">Add to Cart</button>
        <div id='ProductDetails-HoloBranding-Platform' >Powered by <span style={{fontWeight: 'bold', lineHeight:'12px'}}>Holo Commerce <span style={{color: "#FDA451"}}>/</span><span style={{color: "#F0F2FF"}}>/</span></span></div>
    </div>
    )
}

export default ProductDetails;