import './headerbar-signin.scss'


const HeaderBarSignIn = () => {
    return ( 
        <div className="header-bar">
            <img id="header-logo-sign-in" src='https://d23k6qsajefhq1.cloudfront.net/platform-media/holocommerceblacklogo.png'></img>
        </div>
     );
}
 
export default HeaderBarSignIn;