import './previewprojectbtn.scss'
import { useNavigate } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';

const PreviewProjectBtn = (props) => {

    const navigate = useNavigate();

    function previewBtnClick (){
        navigate('/previewproject')
        amplitude.track('Preview Project Btn Clicked');
    }

    return ( 
        <div id="preview-project-btn" onClick={()=> previewBtnClick()}>
            Preview
        </div>
     );
}

export default PreviewProjectBtn;