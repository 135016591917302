import HeaderBar from "../Components/NavbarElement/headerbar";
import NavBar from "../Components/NavbarElement/navbar";
import ProgressPopup from "../Components/VideoUpload/ProgressPopup/progresspopup";
import UploadVideo from "../Containers/UploadVideoContainer/uploadvideo";
import { useState, useCallback } from "react";
import UploadFinished from "../Components/VideoUpload/UploadFinishedPopup/uploadfinished";
import { useGlobalState } from "../Hooks/useGlobalState";

function UploadVideoPage(){
    const [state, dispatch] = useGlobalState();
    const [load, setloading] = useState("")

    const [finished, setFinished] = useState(false)

    const setCurrentProject = useCallback(currentProject => dispatch({currentProject}), []);

    return (  
        <div>
            <UploadVideo setloading={setloading} setFinished={setFinished}></UploadVideo>
            <NavBar setCurrentProject={setCurrentProject} navstate={1} currentProject={state.currentProject}  />
            <HeaderBar></HeaderBar>
            <ProgressPopup load={load}></ProgressPopup>
            <UploadFinished setFinished={setFinished} finished={finished}></UploadFinished>
        </div>
    );
}

export default UploadVideoPage;